<template>
  <div class="step3 apply">
    <div class="form-group policy" v-if="form.new.simulationFlag !== 'Y' && form.new.addInfoItems && form.new.addInfoItems.length">
      <div class="font-sm">
        <div class="content">
          <div class="wrapper" v-for="(item, idx) in form.new.addInfoItems" :key="idx">
            <div class="row">
              <div class="subtitle col-lg-2">
                <label class="mb-0" :for="`${component.name}ItemTitle${idx}`">제목</label>
              </div>
              <div class="col-lg-10">
                <input :id="`${component.name}ItemTitle${idx}`" type="text" class="form-control font-sm border-focus-point" v-model="item.title" @keyup.enter="save()"/>
              </div>
            </div>
            <div class="row pb-0">
              <div class="subtitle col-lg-2">
                <label class="mb-0" :for="`${component.name}ItemContent${idx}`">내용</label>
              </div>
              <div class="col-lg-10">
                <textarea :id="`${component.name}ItemContent${idx}`" class="form-control font-sm border-focus-point" v-model="item.content" @keyup.ctrl.enter="save()"></textarea>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import mixin from "@/scripts/mixin";

function Component(initialize) {
  this.name = "pageApplyRewardMakerstep3";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    form: Object,
    files: Object,
    validators: Object,
    save: Function,
    warn: Function,
  },
  setup(props) {
    const component = new Component(() => {
      props.form.new.contestSeq = props.form.new.contestSeq || "";

      props.validators[3] = () => {
        for (let i in props.form.new.addInfoItems) {
          if (!props.form.new.addInfoItems[i].title?.trim()) {
            return props.warn("제목을 입력해주세요.", `${component.name}ItemTitle${i}`);
          } else if (!props.form.new.addInfoItems[i].content?.trim()) {
            return props.warn("내용을 입력해주세요.", `${component.name}ItemContent${i}`);
          }
        }

        return true;
      };

      http.get("/api/partnerships/contests").then(({data}) => {
        state.contests = data.body;
      });
    });

    const state = reactive({
      contests: [],
    });

    const computedContests = computed(() => state.contests.filter(c => c.contestType !== "S"));

    return {component, state, computedContests};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.apply";

.step3 {
  .form-group {
    &.policy {
      .content {
        background: $colorBackground;
        border: $px1 solid #eee;
        border-radius: $px4;
        padding: $px10 0;

        > .wrapper {
          padding: $px15 $px15 $px15 $px30;

          .row {
            padding-bottom: $px15;

            .subtitle {
              padding-top: $px13;

              span {
                display: inline-block;
                padding: $px10 0;
              }
            }

            textarea {
              min-height: $px137;
            }
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    .form-group.policy .content {
      padding-top: $px5;

      > .wrapper {
        padding: 0 $px15 $px15 $px15;

        .row .subtitle {
          padding-bottom: $px13;
        }
      }
    }
  }
}
</style>