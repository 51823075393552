<template>
  <div class="step6 apply">
    <div class="form-group">
      <div class="guide">
        <div class="title">
          <span>정산 정보</span>
        </div>
      </div>
      <div class="content">
        <table class="table table-bordered font-sm">
          <tbody>
          <tr>
            <th>목표 금액</th>
            <td>{{ form.new.targetAmt ? $lib.getNumberFormat(form.new.targetAmt) : "0" }}원</td>
          </tr>
          <tr>
            <th>
              <span>운영 수수료</span>
              <div class="mt-1 font-xs color-secondary">
                <div>* 성공해야 리워드: 5%</div>
                <div>* 무조건 리워드: 7%</div>
              </div>
            </th>
            <td>{{ $lib.getNumberFormat(computedPlatformCommission) }}원</td>
          </tr>
          <tr>
            <th>
              <span>결제 수수료</span>
              <span class="font-xs color-secondary"> (3%)</span>
            </th>
            <td>{{ $lib.getNumberFormat(computedPayCommission) }}원</td>
          </tr>
          <tr>
            <th>부가세</th>
            <td>{{ $lib.getNumberFormat(computedVat) }}원</td>
          </tr>
          <tr>
            <th>예상 정산 금액</th>
            <td>{{ $lib.getNumberFormat(computedTotalAmount) }}원</td>
          </tr>
          </tbody>
        </table>
      </div>
    </div>
    <div class="form-group file etc">
      <div class="guide">
        <div class="title">
          <span>첨부 파일</span>
        </div>
      </div>
      <div class="content">
        <div class="wrapper">
          <div class="top font-sm">
            <label class="subject mb-0" :for="`${component.name}BizRegFile`">
              <span class="color-point">*</span>
              <span> 정산 서류</span>
              <span class="d-block color-secondary font-xs">(사업자등록증 또는 신분증 사본)</span>
            </label>
            <label class="mb-0 right" @click.stop="clearInput($event)">
              <a class="pointer color-point">파일 올리기</a>
              <input :id="`${component.name}BizRegFile`" type="file" :accept="$definitions.limits.fileExtensions.allStr" class="hide" @change="setFile($event, 'bizRegFile')"/>
            </label>
          </div>
          <ul class="files tight" v-if="files.new.bizRegFile && files.new.bizRegFile.fileSeq ? files.new.bizRegFile.delFlag !== 'Y' : files.new.bizRegFile">
            <li class="font-sm pointer" :type="files.new.bizRegFile.type" @click="$store.dispatch('previewFile', files.new.bizRegFile)" title="클릭 시 미리보기">
              <div>{{ files.new.bizRegFile.fileSeq ? files.new.bizRegFile.fileOrigName : files.new.bizRegFile.name }}</div>
              <span class="pointer times" title="삭제" @click.stop="delFile('bizRegFile')">&times;</span>
            </li>
          </ul>
        </div>
        <div class="wrapper">
          <div class="top font-sm">
            <label class="subject mb-0" :for="`${component.name}Bankbook`">
              <span class="color-point">* </span>
              <span>통장 사본</span>
              <span class="d-block color-secondary font-xs">(사업자의 경우 사업자명의 통장, 개인일 경우 개인명의의 통장)</span>
            </label>
            <label class="mb-0 right" @click.stop="clearInput($event)">
              <a class="pointer color-point">파일 올리기</a>
              <input :id="`${component.name}Bankbook`" type="file" :accept="$definitions.limits.fileExtensions.allStr" class="hide" @change="setFile($event, 'bankbook')"/>
            </label>
          </div>
          <ul class="files tight" v-if="files.new.bankbook && files.new.bankbook.bankbook ? files.new.bankbook.delFlag !== 'Y' : files.new.bankbook">
            <li class="font-sm pointer" :type="files.new.bankbook.type" @click="$store.dispatch('previewFile', files.new.bankbook)" title="클릭 시 미리보기">
              <div>{{ files.new.bankbook.fileSeq ? files.new.bankbook.fileOrigName : files.new.bankbook.name }}</div>
              <span class="pointer times" title="삭제" @click.stop="delFile('bankbook')">&times;</span>
            </li>
          </ul>
        </div>
        <div class="wrapper font-sm">
          <div class="top">
            <label class="subject mb-0" :for="`${component.name}EtcDocFile`">
              <span>기타 서류</span>
              <span class="d-block color-secondary font-xs">(프로젝트를 설명할 기타 서류나 리워드의 종류에 따라 오마이컴퍼니에서 추가 서류를 요청하는 경우)</span>
            </label>
            <label class="mb-0 right" @click.stop="clearInput($event)">
              <a class="pointer color-point">파일 올리기</a>
              <input :id="`${component.name}EtcDocFile`" type="file" :accept="$definitions.limits.fileExtensions.allStr" class="hide" @change="setFile($event, 'etcDocFile')"/>
            </label>
          </div>
          <ul class="files tight" v-if="files.new.etcDocFile && files.new.etcDocFile.fileSeq ? files.new.etcDocFile.delFlag !== 'Y' : files.new.etcDocFile">
            <li class="font-sm pointer" :type="files.new.etcDocFile.type" @click="$store.dispatch('previewFile', files.new.etcDocFile)" title="클릭 시 미리보기">
              <div>{{ files.new.etcDocFile.fileSeq ? files.new.etcDocFile.fileOrigName : files.new.etcDocFile.name }}</div>
              <span class="pointer times" title="삭제" @click.stop="delFile('etcDocFile')">&times;</span>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div class="alert">
      <ul class="tight">
        <li>
          <p>무조건 리워드(Keep it all) 방식의 프로젝트는 마감일 이후 4~7일 이내로, 성공해야 리워드(All or nothing) 방식의 프로젝트는 마감일 이후 7~10일 이내로 수수료를 제외한 실제 정산되는 금액과 전체 후원자 정보가 포함된 정산서를 카카오톡으로 전달합니다.</p>
        </li>
        <li>
          <p>정산서 확인 회신 및 정산용 서류 전달 시점 익일 이후 1~2주 이내 매주 화, 목요일 정산이 진행됩니다.</p>
        </li>
        <li>
          <p>내부 사정 및 공휴일로 인해 정산은 미뤄질 수 있습니다.</p>
        </li>
        <li>
          <div>신청해 주신 프로젝트는 영업일 기준 3일 이내에 담당 매니저가 심사하여 피드백 드립니다.</div>
        </li>
        <li>
          <div>펀딩 시작 전까지 프로젝트 수정이 가능합니다. 마이페이지 > 진행 프로젝트 > 수정하기를 클릭하여 진행 바랍니다.</div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent} from "@vue/composition-api";
import Date from "@/components/Date";
import Number from "@/components/Number";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Phone from "@/components/Phone.vue";

function Component(initialize) {
  this.name = "pageApplyRewardMakerStep6";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    form: Object,
    files: Object,
    validators: Object,
    save: Function,
    warn: Function,
  },
  components: {Phone, Date, Number},
  setup(props) {
    const component = new Component(() => {
      props.validators[6] = () => {
        if (!props.files.new.bizRegFile) {
          return props.warn("정산 서류 파일을 올려주세요.");
        } else if (!props.files.new.bankbook) {
          return props.warn("통장 사본 파일을 올려주세요.");
        }

        return true;
      };
    });

    const computedPlatformCommission = computed(() => {
      if (!props.form.new.targetAmt) {
        return 0;
      }

      return Math.round(props.form.new.targetAmt * (props.form.new.fundingType === "K" ? 0.07 : 0.05));
    });

    const computedPayCommission = computed(() => {
      if (!props.form.new.targetAmt) {
        return 0;
      }

      return Math.round(props.form.new.targetAmt * 0.03);
    });

    const computedVat = computed(() => {
      if (!props.form.new.targetAmt) {
        return 0;
      }

      return Math.round((computedPlatformCommission.value + computedPayCommission.value) * 0.1);
    });

    const computedTotalAmount = computed(() => {
      if (!props.form.new.targetAmt) {
        return 0;
      }

      return props.form.new.targetAmt - computedPlatformCommission.value - computedPayCommission.value - computedVat.value;
    });

    const setFile = (e, name) => {
      if (!store.getters.isAllowedExtension(e.target, "all")) {
        return;
      }

      if (e.target.files.length && props.files.new[name] !== undefined) {
        props.files.new[name]?.fileSeq && props.files.deletes.push(props.files.new[name]);
        props.files.new[name] = e.target.files[0];
      }
    };

    const delFile = (fileCode) => {
      if (props.files.new[fileCode].fileCode) {
        props.files.deletes.push(props.files.new[fileCode]);
      }

      props.files.new[fileCode] = null;
    };

    const clearInput = (e) => {
      e.currentTarget.querySelector("input").value = "";
    };

    return {component, computedPlatformCommission, computedPayCommission, computedVat, computedTotalAmount, setFile, delFile, clearInput};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.apply";

.step6 {
  display: flex;
  flex-direction: column;
  gap: $px70;

  .form-group {
    display: flex;
    justify-content: space-between;
    gap: $px30;

    > .content {
      display: flex;
      flex-direction: column;
      gap: $px24;

      table {
        border: $px1 solid #eee;
        margin-bottom: $px35;

        th {
          background: $colorBackground;
          width: $px170;
        }
      }

      > .wrapper {
        > .top {
          display: flex;
          justify-content: space-between;
          align-items: center;
          gap: $px16;

          .right {
            flex-shrink: 0;
          }
        }
      }
    }
  }

  .alert {
    border: $px1 solid #eee;
    background: $colorBackground;
    padding: $px20;
    font-size: $px13;
    color: #3c3c3c;
    margin-bottom: $px25;

    p {
      margin-bottom: $px5;
    }
  }

  @media(max-width: 991px) {
    gap: $px24;

    .form-group {
      flex-direction: column;
      gap: $px16;

      > .content {
        > .row {
          gap: $px16;
        }
      }
    }
  }
}
</style>