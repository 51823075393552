<template>
  <div class="step1 apply">
    <div class="form-group">
      <div class="guide">
        <div class="title">
          <span>프로젝트 정보</span>
        </div>
      </div>
      <div class="content">
        <div class="wrapper">
          <div class="subject">
            <span class="color-point">*</span>
            <span> 카테고리</span>
          </div>
          <div class="d-flex">
            <label class="m-0" v-for="(c,idx) in state.categories" :key="idx">
              <input type="radio" :id="`${component.name}Category`" v-model="form.new.projectCate" :value="c.codeId">
              <span class="font-sm"> {{ c.codeIdName }}</span>
            </label>
          </div>
        </div>
        <template v-if="form.new.simulationFlag === 'N'">
          <div class="wrapper">
            <div class="subject">
              <span class="color-point">*</span>
              <span> 후원 가능 연령</span>
            </div>
            <div class="d-flex">
              <label class="m-0"><input type="radio" :name="`${component.name}AdultFlag`" value="N" v-model="form.new.adultFlag"><span class="font-sm"> 전체</span></label>
              <label class="m-0"><input type="radio" :name="`${component.name}AdultFlag`" value="Y" v-model="form.new.adultFlag"><span class="font-sm"> 성인 (만 19세 이상)</span></label>
            </div>
          </div>
          <div class="wrapper">
            <div class="subject">
              <span class="color-point">*</span>
              <span> 진행 방식</span>
              <div class="dropdown d-inline-block">
                <i class="fa fa-question-circle-o color-point pl-2" type="button" data-toggle="dropdown" data-display="static" aria-expanded="false"></i>
                <div class="dropdown-menu font-sm">
                  <b>무조건 리워드 (KIA, Keep It All)</b>
                  <div class="texts">프로젝트 참여와 동시에 결제가 진행되며, 목표 금액을 달성하지 못하더라도 예정된 리워드를 제공하고, 모집금액에서 수수료를 제한 나머지 금액을 정산합니다. *플랫폼 수수료 7% + 결제 수수료 3% (부가세 10% 별도)</div>
                  <b>성공해야 리워드 (AON, All Or Nothing)</b>
                  <div class="texts">목표금액이 100% 달성되어야 프로젝트 참여건에 대한 예약 결제가 진행되고, 리워드 제공 및 모집금액에 대한 정산이 진행됩니다. 목표금액을 달성하지 못한 경우에는 결제가 진행되지 않으며, 리워드를 제공하지 않습니다. *플랫폼 수수료 5% + 결제 수수료 3% (부가세 10% 별도)</div>
                </div>
              </div>
              <div class="color-secondary font-xs">
                <span>펀딩 방식은 프로젝트 오픈 이후 수정이 불가합니다.</span>
              </div>
            </div>
            <div class="d-flex">
              <label class="m-0"><input type="radio" :name="`${component.name}FundingType`" value="K" v-model="form.new.fundingType" checked><span class="font-sm"> 무조건 리워드</span></label>
              <label class="m-0"><input type="radio" :name="`${component.name}FundingType`" value="A" v-model="form.new.fundingType"><span class="font-sm"> 성공해야 리워드</span></label>
            </div>
          </div>
          <div class="wrapper">
            <div class="subject">
              <span class="color-point">*</span>
              <span> 후원자 주소 정보</span>
              <div class="dropdown d-inline-block">
                <i class="fa fa-question-circle-o color-point pl-2" type="button" data-toggle="dropdown" data-display="static" aria-expanded="false"></i>
                <div class="dropdown-menu font-sm">
                  <span>후원자에게 직접 배송하는 형태의 리워드가 아닌 경우 입력받지 않음을 체크하시면 후원자 분들에게 따로 주소 정보를 입력받지 않습니다.</span>
                </div>
              </div>
            </div>
            <div class="d-flex">
              <label class="m-0"><input type="radio" :name="`${component.name}AddressRequired`" value="N" v-model="form.new.skipAddressFlag" checked><span class="font-sm"> 입력 받기</span></label>
              <label class="m-0"><input type="radio" :name="`${component.name}AddressRequired`" value="Y" v-model="form.new.skipAddressFlag"><span class="font-sm"> 입력 받지 않기</span></label>
            </div>
          </div>
          <div class="wrapper">
            <div class="subject">
              <span class="color-point">* </span>
              <span>프로젝트 진행 이력</span>
            </div>
            <div class="d-flex">
              <label class="m-0"><input type="radio" :name="`${component.name}HistoryProjectFlag`" value="N" v-model="form.new.historyProjectFlag" checked><span class="font-sm"> 이력 없음</span></label>
              <label class="m-0"><input type="radio" :name="`${component.name}HistoryProjectFlag`" value="Y" v-model="form.new.historyProjectFlag"><span class="font-sm"> 이력 있음</span></label>
            </div>
          </div>
          <div class="wrapper" v-if="form.new.historyProjectFlag === 'Y'">
          <div class="subject">
            <span class="color-point">* </span>
            <span>과거 진행 프로젝트 URL</span>
          </div>
          <input type="text" class="form-control border-focus-point font-sm" :id="`${component.name}HistoryUrl`" placeholder="https://ohmycompany.com/" v-model="form.new.historyUrl"/>
        </div>
        </template>
      </div>
    </div>
    <div class="form-group">
      <div class="guide">
        <div class="title">
          <span>금액 및 기간</span>
        </div>
      </div>
      <div class="content">
        <div class="wrapper">
          <label class="subject" :for="`${component.name}InvestStartDate`">
            <span class="color-point" v-if="form.new.simulationFlag !== 'Y'">* </span>
            <span>목표 금액</span>
            <div class="color-secondary font-xs" v-if="form.new.simulationFlag === 'N'">
              <span>펀딩 최소 목표 금액은 50만 원입니다.</span>
            </div>
          </label>
          <Number :id="`${component.name}TargetAmt`" placeholder="펀딩 목표 금액을 입력해 주세요. (단위: 원)" :value.sync="form.new.targetAmt" :enter="save"/>
        </div>
        <template v-if="form.new.simulationFlag !== 'Y'">
          <div class="row">
            <div class="col-lg-6">
              <label class="subject" :for="`${component.name}InvestStartDate`">
                <span class="color-point">* </span>
                <span>펀딩 시작일</span>
              </label>
              <Date componentNameSuffix="S" :id="`${component.name}InvestStartDate`" class="font-sm" placeholder="시작일" :value.sync="form.new.investStartDate"/>
            </div>
            <div class="col-lg-6">
              <label class="subject" :for="`${component.name}InvestEndDate`">
                <span class="color-point">* </span>
                <span>펀딩 종료일</span>
              </label>
              <Date componentNameSuffix="E" :id="`${component.name}InvestEndDate`" class="font-sm" placeholder="종료일" :value.sync="form.new.investEndDate"/>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-6">
              <label class="subject" :for="`${component.name}openReservationTime`">
                <span class="color-point">* </span>
                <span>희망 오픈 시간</span>
              </label>
              <select :id="`${component.name}openReservationTime`" class="form-control border-focus-point font-sm" v-model="form.new.openReservationTime">
                <option :value="$lib.getNumberWithPadding(i - 1) + '0000'" v-for="i in 24" :key="i">{{ $lib.getNumberWithPadding(i - 1) }}:00</option>
              </select>
            </div>
            <div class="col-lg-6">
              <label class="subject" :for="`${component.name}InvestStartDate`">
                <span>펀딩 결제 종료일</span>
                <div class="dropdown d-inline-block">
                  <i class="fa fa-question-circle-o color-point pl-2" type="button" data-toggle="dropdown" data-display="static" aria-expanded="false"></i>
                  <div class="dropdown-menu font-sm">
                    <b>무조건 리워드 선택 시</b>
                    <div class="texts">
                      <span>- 프로젝트 정산서는 종료일 기준 약 4일 후에 발송됩니다.</span>
                      <span>- 정산서 확인이 완료되면 정산까지 약 1주 소요됩니다.</span>
                    </div>
                    <b>성공해야 리워드 선택 시</b>
                    <div class="texts">
                      <span>- 정산서는 프로젝트 종료일 기준 약 7일 후에 발송됩니다.</span>
                      <span>- 정산서 확인이 완료되면 정산까지 약 1주 소요됩니다.</span>
                    </div>
                  </div>
                </div>
              </label>
              <input class="form-control font-sm border-focus-point" type="text" :value="computedFundingPaymentEndDate" readonly placeholder="펀딩 진행 방식, 펀딩 종료일로 자동 출력">
            </div>
          </div>
        </template>
      </div>
    </div>
    <div class="form-group">
      <div class="guide">
        <div class="title">
          <span>파트너십 정보</span>
        </div>
      </div>
      <div class="content" v-if="form.new.simulationFlag === 'Y'">
        <div class="row">
          <div class="col-6">
            <label class="subject">
              <span>파트너십</span>
            </label>
            <input type="text" class="form-control border-focus-point font-sm" :value="state.partnerShipDetail.contestName" disabled/>
          </div>
          <div class="col-6">
            <label class="subject">
              <span>펀딩 기간</span>
            </label>
            <input type="text" class="form-control border-focus-point font-sm"
                   :value="`${$lib.getDateFormat(state.partnerShipDetail.fundingStartDate, 'yyyy-MM-dd')} ~ ${$lib.getDateFormat(state.partnerShipDetail.fundingEndDate, 'yyyy-MM-dd')}`" disabled/>
          </div>
        </div>
      </div>
      <div class="content" v-else>
        <div class="wrapper">
          <div class="subject">
            <span class="color-point">* </span>
            <span>파트너십 관련 여부</span>
          </div>
          <div class="d-flex">
            <label class="m-0"><input type="radio" :name="`${component.name}PartnershipFlag`" value="N" v-model="form.new.partnershipFlag" checked><span class="font-sm"> 관련 없음</span></label>
            <label class="m-0"><input type="radio" :name="`${component.name}PartnershipFlag`" value="Y" v-model="form.new.partnershipFlag"><span class="font-sm"> 관련 있음</span></label>
          </div>
        </div>
        <template v-if="form.new.partnershipFlag === 'Y'">
          <div class="row">
            <div class="col-6">
              <label class="subject" :for="`${component.name}Partnership`">
                <span class="color-point">* </span>
                <span>연관 파트너십 대회</span>
              </label>
              <select :id="`${component.name}Partnership`" class="form-control border-focus-point" v-model="form.new.contestSeq">
                <option :value="null">연관 파트너십 대회를 선택해주세요</option>
                <option :value="c.contestSeq" v-for="c in state.contests" :key="c.contestSeq">{{ c.contestName }}</option>
              </select>
            </div>
            <div class="col-6">
              <div class="subject">
                <span>법인 설립일</span>
              </div>
              <Date componentNameSuffix="C" :value.sync="form.new.corpEstablished" format="yyyyMMdd" placeholder="법인 가입자일 시 설립일을 입력해주세요" classList="font-sm"/>
            </div>
          </div>
          <div class="wrapper">
            <div class="subject">
              <span class="color-point">* </span>
              <span>조직 형태</span>
            </div>
            <div class="d-flex">
              <label class="m-0" v-for="o in state.codes.orgTypes" :key="o.codeId">
                <input type="radio" :name="`${component.name}GroupCate`" :value="o.codeId" v-model="form.new.groupCate"><span class="font-sm"> {{ o.codeIdName }}</span>
              </label>
            </div>
          </div>
          <div class="wrapper">
            <div class="subject">
              <span class="color-point">* </span>
              <span>인증 여부</span>
            </div>
            <div class="d-flex">
              <label class="m-0" v-for="o in state.codes.orgAuthTypes" :key="o.codeId">
                <input type="radio" :name="`${component.name}CorpSector`" :value="o.codeId" v-model="form.new.corpSector"><span class="font-sm"> {{ o.codeIdName }}</span>
              </label>
            </div>
          </div>
          <div class="wrapper" v-if="form.new.corpSector && form.new.corpSector !== 'ORG_AUTH_9999'">
            <div class="subject">
              <span class="color-point" v-if="form.new.corpSector !== 'ORG_AUTH_0009'">* </span>
              <span>인증 서류</span>
            </div>
            <div class="file">
              <label class="d-flex justify-content-between font-sm">
                <span>위 인증 부문 관련 인증 서류</span>
                <span class="color-anchor font-sm pointer">파일 업로드</span>
                <input type="file" class="hide" :accept="$definitions.limits.fileExtensions.allStr" @change="setFile($event)" :id="`${component.name}CertFile`">
              </label>
              <ul class="files tight" v-if="files.new.certFile && files.new.certFile.fileSeq ? files.new.certFile.delFlag !== 'Y' : files.new.certFile">
                <li class="font-sm pointer" :type="files.new.certFile.type" @click="$store.dispatch('previewFile', files.new.certFile)" title="클릭 시 미리보기">
                  <div>{{ files.new.certFile.fileOrigName || files.new.certFile.name }}</div>
                  <span class="pointer times" title="삭제" @click.stop="delFile()">&times;</span>
                </li>
              </ul>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="subject">
                <span>지역</span>
              </div>
              <select class="form-control" v-model="form.new.corpRegion">
                <option :value="null">지역을 선택해주세요</option>
                <option class="font-sm" :value="o.codeId" v-for="o in state.codes.corpRegions" :key="o.codeId">{{ o.codeIdName }}</option>
              </select>
            </div>
            <div class="col-6">
              <div class="subject">
                <span>진행자 성별</span>
              </div>
              <select :id="`${component.name}UserSex`" class="form-control border-focus-point font-sm" v-model="form.new.userSex">
                <option :value="null">성별을 선택해주세요</option>
                <option value="M">남성</option>
                <option value="F">여성</option>
              </select>
            </div>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import TinyEditor from "@/components/TinyEditor";
import mixin from "@/scripts/mixin";
import Number from "@/components/Number.vue";
import Date from "@/components/Date.vue";
import {httpError} from "@/scripts/httpError";
import store from "@/scripts/store";

function Component(initialize) {
  this.name = "pageApplyNewRewardMakerStep1";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    form: Object,
    files: Object,
    validators: Object,
    save: Function,
    warn: Function,
    keys: Object,
  },
  components: {Date, Number, TinyEditor},
  setup(props) {
    const component = new Component(() => {
      state.video = props.form.new.videoFlag === "Y";

      const promises = [
        http.get("/api/codes?parentCodeId=ORG_TYPE").catch(httpError()),
        http.get("/api/codes?parentCodeId=ORG_AUTH_TYPE").catch(httpError()),
        http.get("/api/codes?parentCodeId=CORP_REGION").catch(httpError()),
      ];

      http.get("/api/reward/categories").then(({data}) => {
        state.categories = data.body;
      });

      http.get("/api/partnerships/contests").then(({data}) => {
        state.contests = data.body?.filter(c => c.contestType !== "S");
      });

      Promise.all(promises).then(responses => {
        state.codes.orgTypes = responses[0].data.body;
        state.codes.orgAuthTypes = responses[1].data.body;
        state.codes.corpRegions = responses[2].data.body;
      });

      if (props.form.new.simulationFlag === "Y") {
        http.get(`/api/partnerships/contests/${props.form.new.contestSeq}`).then(({data}) => {
          state.partnerShipDetail = data.body;
        });
      }

      props.validators[1] = () => {
        if (props.form.new.simulationFlag !== "Y") {
          if (props.form.new.historyProjectFlag === "Y" && !props.form.new.historyUrl) {
            return props.warn("과거 진행 프로젝트 URL을 입력해주세요", `${component.name}HistoryUrl`);
          } else if (!props.form.new.investStartDate) {
            return props.warn("펀딩 시작일을 입력해주세요.", `${component.name}InvestStartDate`);
          } else if (props.form.new.investStartDate < lib.getDateFormat(new window.Date(), "yyyyMMdd")) {
            return props.warn("펀딩 시작일을 오늘 혹은 이후의 날짜로 입력해주세요.", `${component.name}InvestStartDate`);
          } else if (!props.form.new.investEndDate) {
            return props.warn("펀딩 종료일을 입력해주세요.", `${component.name}InvestEndDate`);
          } else if (props.form.new.investEndDate <= lib.getDateFormat(new window.Date(), "yyyyMMdd")) {
            return props.warn("펀딩 종료일을 오늘 이후의 날짜로 입력해주세요.", `${component.name}InvestEndDate`);
          } else if (props.form.new.investStartDate >= props.form.new.investEndDate) {
            return props.warn("펀딩 종료일을 시작일 이후의 날짜로 입력해주세요.", `${component.name}InvestEndDate`);
          } else if (!props.form.new.openReservationTime) {
            return props.warn("펀딩 오픈 시간을 입력해주세요.", `${component.name}openReservationTime`);
          } else if (!props.form.new.targetAmt) {
            return props.warn("펀딩 목표 금액을 입력해주세요.", `${component.name}TargetAmt`);
          } else if (!props.form.new.fundingType) {
            return props.warn("펀딩 방식을 선택해주세요.", `${component.name}FundingType`);
          } else if (props.form.new.partnershipFlag === "Y") {
            if (!props.form.new.contestSeq) {
              return props.warn("연관 파트너십 대회를 선택해주세요.", `${component.name}Partnership`);
            } else if (!props.form.new.groupCate) {
              return props.warn("조직 형태를 선택해주세요.");
            } else if (!props.form.new.corpSector) {
              return props.warn("인증 여부를 선택해주세요.");
            } else if (!["ORG_AUTH_0009", "ORG_AUTH_9999"].includes(props.form.new.corpSector) && !props.files.new.certFile) {
              return props.warn("인증 서류를 업로드 해주세요.", `${component.name}CertFile`);
            }
          }
        }

        return true;
      };
    });

    const state = reactive({
      codes: {
        orgTypes: [],
        orgAuthTypes: [],
        corpRegions: [],
      },
      contests: [],
      categories: [],
      partnerShipDetail: {
        contestName: "",
        fundingStartDate: "",
        fundingEndDate: "",
      },
    });

    const clearInput = (e) => {
      e.currentTarget.querySelector("input").value = "";
    };

    const applyFromModal = (params) => {
      props.form.new.projectInfo = params.projectInfo;
      props.keys.editor += 1;
    };

    const computedFundingPaymentEndDate = computed(() => {
      if (!props.form.new.investEndDate) {
        return "";
      }

      const year = parseInt(props.form.new.investEndDate.substring(0, 4));
      const month = parseInt(props.form.new.investEndDate.substring(4, 6)) - 1;
      const day = parseInt(props.form.new.investEndDate.substring(6, 8));
      const date = new window.Date(year, month, day);

      if (props.form.new.fundingType === "A") {
        date.setDate(date.getDate() + 3);
      }

      return `${date.getFullYear()}년 ${date.getMonth() + 1}월 ${date.getDate()}일`;
    });

    const setFile = (e) => {
      if (!store.getters.isAllowedExtension(e.target, "all")) {
        return;
      }

      if (e.target.files.length && props.files.new.certFile !== undefined) {
        props.files.new.certFile?.fileSeq && props.files.deletes.push(props.files.new.certFile);
        props.files.new.certFile = e.target.files[0];
      }
    };

    const delFile = () => {
      if (props.files.new.certFile.fileCode) {
        props.files.deletes.push(props.files.new.certFile);
      }

      props.files.new.certFile = null;
    };

    return {
      component,
      state,
      computedFundingPaymentEndDate,
      clearInput,
      applyFromModal,
      setFile,
      delFile,
    };
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.apply";

.step1 {
  display: flex;
  flex-direction: column;
  gap: $px70;

  .form-group {
    display: flex;
    justify-content: space-between;
    gap: $px30;

    > .content {
      display: flex;
      flex-direction: column;
      gap: $px24;

      .subject {
        .dropdown-menu {
          padding: $px16;
          max-width: $px400;
          min-width: $px300;

          .texts {
            &:not(:last-child) {
              margin-bottom: $px16;
            }
          }
        }
      }

      > .wrapper {
        > .d-flex {
          flex-wrap: wrap;
          white-space: nowrap;
          gap: $px8;
          align-items: center;

          span {
            vertical-align: text-bottom;
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    gap: $px24;

    .form-group {
      flex-direction: column;
      gap: $px16;

      > .content {
        > .row {
          gap: $px16;
        }
      }
    }
  }
}
</style>