<template>
  <div class="step5 apply" :id="`${component.name}Step5`" v-if="state.loaded">
    <div class="form-group" v-if="$store.state.account.memberType === 'C'">
      <div class="guide">
        <div class="title">
          <span>기업 정보</span>
        </div>
      </div>
      <div class="content">
        <div class="input">
          <label class="subject" :for="`${component.name}CorporateName`">
            <span class="color-point">*</span>
            <span> 기업명</span>
          </label>
          <input :id="`${component.name}CorporateName`" type="text" class="form-control border-focus-point" maxlength="30" :value="form.new.corporateName" @input="form.new.corporateName = $event.target.value"
                 @keyup.enter="save()" disabled/>
        </div>
        <div class="input">
          <label class="subject" :for="`${component.name}UserAddress`">
            <span class="color-point">*</span>
            <span> 주소</span>
          </label>
          <Address :id="`${component.name}UserAddress`" :zoneCode.sync="form.new.userPost" :address1.sync="form.new.userAddr1" :address2.sync="form.new.userAddr2" :enter="save"/>
        </div>
        <div class="row">
          <div class="col-6">
            <label :for="`${component.name}GroupCate`" class="subject">
              <span class="color-point">* </span>
              <span>조직 형태</span>
            </label>
            <select :id="`${component.name}GroupCate`" class="form-control border-focus-point font-sm" v-model="form.new.groupCate">
              <option :value="null">선택</option>
              <option :value="c.codeId" v-for="(c, idx) in state.codes.orgTypes" :key="idx">{{ c.codeIdName }}</option>
            </select>
          </div>
          <div class="col-6">
            <label :for="`${component.name}CorpSector`" class="subject">
              <span class="color-point">* </span>
              <span>사회적경제조직 유형</span>
            </label>
            <select :id="`${component.name}CorpSector`" class="form-control border-focus-point font-sm" v-model="form.new.corpSector">
              <option :value="null">선택</option>
              <option :value="c.codeId" v-for="(c, idx) in state.codes.orgAuthTypes" :key="idx">{{ c.codeIdName }}</option>
            </select>
          </div>
        </div>
        <div class="wrapper top">
          <label :for="`${component.name}SocialFiles`" class="subject">
            <span>사회적경제조직 인증서</span>
            <span class="font-xs"> ({{ files.new.socialFiles.length }}/{{ maxSocialFilesCnt }})</span>
          </label>
          <a class="color-point pointer font-sm" @click="setFiles()" v-if="files.new.socialFiles.length < maxSocialFilesCnt">파일 업로드</a>
        </div>
        <ul class="files tight" v-if="files.new.socialFiles.length > 0">
          <li class="font-sm pointer" data-type="image" v-for="(f, idx) in files.new.socialFiles" :key="idx" @click="$store.dispatch('previewFile', f)" title="클릭 시 미리보기">
            <div>{{ f.fileSeq ? f.fileOrigName : f.name }}</div>
            <span class="pointer times" title="삭제" @click.stop="delSocialFile(idx)">&times;</span>
          </li>
        </ul>
      </div>
    </div>
    <div class="form-group">
      <div class="guide">
        <div class="title">
          <span>진행자 소개</span>
        </div>
      </div>
      <div class="content">
        <div class="row picture" title="프로필 사진">
          <div class="col-6">
            <div class="txt">
              <span class="color-point">* </span>
              <span>프로필 사진<span class="desc">(비율 1 : 1)</span></span>
            </div>
            <label class="form-control">
              <span>프로필 사진 등록</span>
              <input type="file" @change="setImage($event)" :accept="$definitions.limits.fileExtensions.imgStr">
            </label>
          </div>
          <div class="col-6 d-flex justify-content-end">
            <MyPicture :id="`${component.name}Picture`" :size="70"/>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label class="subject" :for="`${component.name}CorporateName`">
              <span class="color-point">*</span>
              <span> 이름</span>
            </label>
            <input :id="`${component.name}MemberName`" type="text" class="form-control border-focus-point" maxlength="30" :value="form.new.memberName" @keyup.enter="save()" readonly/>
          </div>
          <div class="col-6">
            <label :for="`${component.name}UserSex`" class="subject">
              <span class="color-point">* </span>
              <span>성별</span>
            </label>
            <select :id="`${component.name}UserSex`" class="form-control border-focus-point font-sm" v-model="form.new.userSex">
              <option value="M">남성</option>
              <option value="F">여성</option>
            </select>
          </div>
        </div>
        <div class="row">
          <div class="col-6">
            <label :for="`${component.name}AgeGroups`" class="subject">
              <span class="color-point">* </span>
              <span>연령대</span>
            </label>
            <select :id="`${component.name}AgeGroups`" class="form-control border-focus-point font-sm" v-model="form.new.ageGroups">
              <option :value="null">선택</option>
              <option value="20">20대</option>
              <option value="30">30대</option>
              <option value="40">40대</option>
              <option value="50">50대</option>
              <option value="60">60대</option>
              <option value="70">70대 이상</option>
            </select>
          </div>
          <div class="col-6">
            <label class="subject" :for="`${component.name}UserHp`">
              <span class="color-point">*</span>
              <span> 연락처</span>
            </label>
            <Phone :id="`${component.name}UserHp`" :value.sync="form.new.userHp" placeholder="ex) 01012345678" :enter="save"/>
          </div>
        </div>
        <div class="input">
          <label :for="`${component.name}UserMail`" class="subject">
            <span class="color-point">*</span>
            <span> 이메일</span>
          </label>
          <input type="email" class="form-control border-focus-point font-sm" :id="`${component.name}UserMail`" placeholder="ex) example@domain.com" v-model="form.new.userMail" @keyup.enter="save()"/>
        </div>
        <div class="input" v-if="$store.state.account.memberType !== 'C'">
          <label class="subject font-sm" :for="`${component.name}Address`">
            <span class="color-point">*</span>
            <span> 주소</span>
          </label>
          <Address :id="`${component.name}Address`" :zoneCode.sync="form.new.userPost" :address1.sync="form.new.userAddr1" :address2.sync="form.new.userAddr2" :enter="save"/>
        </div>
        <div class="input">
          <label :for="`${component.name}Intro`" class="subject">
            <span class="color-point">*</span>
            <span> 소개</span>
            <span class="font-xs color-secondary pl-1">{{ `(${form.new.introduction ? form.new.introduction.trim().length : 0} / 170)` }}</span>
          </label>
          <textarea :id="`${component.name}Intro`" class="form-control border-focus-point" v-model="form.new.introduction" maxlength="170"></textarea>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="guide">
        <div class="title">
          <span>담당자 정보</span>
        </div>
        <div class="desc">
          <span>펀딩을 진행하는 실무 담당자가 있을 경우에만 입력해 주세요.</span>
        </div>
      </div>
      <div class="content">
        <div class="row">
          <div class="col-6">
            <label class="subject" :for="`${component.name}ManagerName`">
              <span> 이름</span>
            </label>
            <input :id="`${component.name}ManagerName`" type="text" class="form-control border-focus-point" maxlength="30" v-model="form.new.managerName" @keyup.enter="save()" placeholder="담당자 이름을 입력해 주세요."/>
          </div>
          <div class="col-6">
            <label class="subject" :for="`${component.name}ManagerPhone`">
              <span> 연락처</span>
            </label>
            <Phone :id="`${component.name}ManagerPhone`" :value.sync="form.new.managerPhone" placeholder="담당자 연락처를 입력해 주세요." :enter="save"/>
          </div>
        </div>
        <div class="input">
          <label class="subject" :for="`${component.name}ManagerEmail`">
            <span> 이메일</span>
          </label>
          <input :id="`${component.name}ManagerEmail`" type="text" class="form-control border-focus-point" maxlength="30" v-model="form.new.managerEmail" @keyup.enter="save()" placeholder="담당자 이메일을 입력해 주세요."/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive} from "@vue/composition-api";
import TinyEditor from "@/components/TinyEditor";
import mixin from "@/scripts/mixin";
import RewardApplyImageSlide from "@/components/RewardApplyImageSlide.vue";
import SimpleTinyEditor from "@/components/SimpleTinyEditor.vue";
import RewardApplyTextSection from "@/components/RewardApplyTextSection.vue";
import Address from "@/components/Address.vue";
import http from "@/scripts/http";
import {httpError} from "@/scripts/httpError";
import Phone from "@/components/Phone.vue";
import store from "@/scripts/store";
import MyPicture from "@/components/MyPicture.vue";
import env from "@/scripts/env";

function Component(initialize) {
  this.name = "pageApplyNewRewardStep5";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    form: Object,
    files: Object,
    validators: Object,
    trigger: Boolean,
    save: Function,
    warn: Function,
    keys: Object,
  },
  components: {MyPicture, Phone, Address, RewardApplyTextSection, SimpleTinyEditor, RewardApplyImageSlide, TinyEditor},
  setup(props) {
    const component = new Component(() => {
      props.validators[5] = async () => {
        if (store.state.account.memberType === "C") {
          if (!props.form.new.userAddr2) {
            return props.warn("상세 주소를 입력해주세요.", `${component.name}Address`);
          } else if (!props.form.new.groupCate) {
            return props.warn("조직 형태를 선택해주세요.", `${component.name}GroupCate`);
          } else if (!props.form.new.corpSector) {
            return props.warn("인증 여부를 선택해주세요.", `${component.name}CorpSector`);
          }
        }

        const profileFilePath = await new Promise((resolve, reject) => {
          http.get(store.getters.profileImageUrl()).then((res) => {
            resolve(res.request?.responseURL);
          }).catch(() => {
            reject();
          });
        });


        if (!profileFilePath || profileFilePath.endsWith(env.profileFileDefaultPath)) {
          return props.warn("프로필 사진을 등록해주세요.");
        } else if (!props.form.new.memberName) {
          return props.warn("진행자 이름을 입력해주세요.", `${component.name}MemberName`);
        } else if (!props.form.new.ageGroups) {
          return props.warn("연령대를 선택해주세요.", `${component.name}AgeGroups`);
        } else if (!props.form.new.userHp) {
          return props.warn("연락처를 입력해주세요.", `${component.name}UserHp`);
        } else if (!props.form.new.userMail) {
          return props.warn("이메일을 입력해주세요.", `${component.name}UserMail`);
        } else if (!props.form.new.userAddr2) {
          return props.warn("주소를 입력해주세요.", `${component.name}Address`);
        } else if (!props.form.new.introduction || !props.form.new.introduction?.trim()) {
          return props.warn("소개 내용을 작성해주세요", `${component.name}Intro`);
        }

        return true;
      };

      state.loaded = false;
      state.loaded = true;
    });

    const promises = [
      http.get("/api/codes?parentCodeId=ORG_TYPE").catch(httpError()),
      http.get("/api/codes?parentCodeId=ORG_AUTH_TYPE").catch(httpError()),
      http.get("/api/codes?parentCodeId=CORP_REGION").catch(httpError()),
    ];

    Promise.all(promises).then(responses => {
      state.codes.orgTypes = responses[0].data.body;
      state.codes.orgAuthTypes = responses[1].data.body;
      state.codes.corpRegions = responses[2].data.body;
    });

    const state = reactive({
      video: false,
      loaded: false,
      files: {
        updates: [],
        deletes: [],
      },
      temp: [],
      codes: {
        orgTypes: [],
        orgAuthTypes: [],
        corpRegions: [],
      },
    });

    const clearInput = (e) => {
      e.currentTarget.querySelector("input").value = "";
    };

    const maxSocialFilesCnt = 5;

    const setFiles = () => {
      const isAllowedCnt = (filesCnt = 1) => {
        return props.files.new.socialFiles.length + filesCnt <= maxSocialFilesCnt;
      };

      if (!isAllowedCnt()) {
        store.commit("setSwingMessage", `최대 업로드 가능한 파일 개수(${maxSocialFilesCnt}개)를 초과하였습니다.`);
        return;
      }

      store.commit("openModal", {
        name: "Uploader",
        params: {
          model: `${component.name}.files.new.socialFiles`,
          maxCnt: maxSocialFilesCnt,
          maxCntLabel: maxSocialFilesCnt,
          image: true,
        },
      });
    };

    const delSocialFile = (idx) => {
      if (props.files.new.socialFiles[idx].fileCode) {
        props.files.deletes.push(props.files.new.socialFiles[idx]);
      }

      props.files.new.socialFiles.splice(idx, 1);
    };

    const setImage = (e) => {
      if (!store.getters.isAllowedExtension(e.target, "image")) {
        return;
      }

      store.commit("confirm", {
        message: "프로필 사진을 업로드하시겠습니까?",
        allow() {
          const formData = new FormData();
          formData.append("memberImgFile", e.target.files[0]);
          e.target.value = "";
          http.put(`/api/members/${store.state.account.memberSeq}/account/uploads`, formData).then(() => {
            store.commit("checkAccount");
            store.commit("setSwingMessage", "프로필 사진을 업로드하였습니다.");
            store.commit("increaseKey", {key: "profile"});
          }).catch(httpError());
        },
        disallow() {
          e.target.value = "";
        }
      });
    };

    return {
      component,
      state,
      clearInput,
      maxSocialFilesCnt,
      setFiles,
      delSocialFile,
      setImage,
    };
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.apply";

.step5 {
  display: flex;
  flex-direction: column;
  gap: $px70;

  > .form-group {
    display: flex;
    justify-content: space-between;
    gap: $px30;

    > .content {
      display: flex;
      flex-direction: column;
      gap: $px16;

      > .wrapper {
        display: flex;
        flex-direction: column;
        gap: $px16;

        &.top {
          justify-content: space-between;
          flex-direction: row;
        }
      }

      > .input {
        textarea {
          min-height: $px100;
        }
      }

      > .picture {
        .txt {
          margin-bottom: $px10;

          .desc {
            color: $colorSecondary;
            font-size: $px13;
            padding-left: $px4;
          }
        }

        label {
          height: $formHeight;
          cursor: pointer;
          display: flex;
          justify-content: center;
          align-items: center;
          transition: background-color 0.18s;

          input[type="file"] {
            display: none;
            width: 0;
            height: 0;
            font-size: 0;
          }

          &:hover {
            background-color: $colorBackground;
          }
        }
      }
    }
  }

  @media(max-width: 991px) {
    gap: $px24;

    .form-group {
      flex-direction: column;
      gap: $px16;
    }
  }
}
</style>