<!-- #517 꺽쇠 이슈로 - TinyEditor를 추가하는 경우 filter-excepts-replace.json 예외 추가 필요 -->
<template>
  <textarea :id="id" class="tiny-editor border-focus-point form-control" :class="page" v-html="computedValue" ref="textareaRef"></textarea>
</template>

<script>
import {computed, defineComponent, onBeforeUnmount, onMounted, reactive, ref,} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import lib from "@/scripts/lib";
import definitions from "@/scripts/definitions";
import http from "@/scripts/http";
import {httpError} from "@/scripts/httpError";
import env from "@/scripts/env";

function Component(initialize) {
  this.name = "componentSimpleTinyEditor";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    id: String,
    value: String,
    height: Number,
    disabled: Boolean,
    maxLength: Number,
    placeholder: String,
    page: String,
    allowImage: Boolean,
  },
  setup(props, {emit}) {
    const component = new Component(() => {
    });

    const state = reactive({
      instance: null
    });

    const textareaRef = ref();

    const computedValue = computed(() => {
      if (props.value) {
        return props.value.split("&lt;").join("&amp;lt;").split("&gt;").join("&amp;gt;");
      }

      return props.value;
    });

    const getTinymceConfig = (options) => {
      const contentCss = options.contentCss;
      const widthRate = options.widthRate;
      const mode = options.mode;
      const fullscreen = options.fullscreen;
      const $ = window.jQuery;

      const editorImageUploadHandler = (blobInfo, success, failure) => {
        const file = blobInfo.blob();
        const imageSize = file.size; // image size in bytes
        const maxFileSize = lib.getMbToByte(definitions.limits.maxFileSize.replace("MB", ""));

        if (imageSize > maxFileSize) {
          failure(`이미지의 용량이 너무 큽니다. ${definitions.limits.maxFileSize} 이하로 올려주세요.`);
          return false;
        }

        const ext = lib.getFileExtension(file.name);

        if (!definitions.limits.fileExtensions.all.includes(ext)) {
          failure(definitions.messages.notAllowedFileExtension);
          return;
        }

        const key = lib.getRandomNum(1000, 9999) + "" + lib.getDateFormat(new Date(), "yyMMddHHmmss");
        const formData = new FormData();
        formData.append("files", file, key + "." + ext);

        http.post("/api/editor/upload", formData).then(({data}) => {
          if (data.body.length) {
            success(data.body[0].location);
            state.instance.selection.collapse();
          } else {
            typeof failure === "function" && failure();
          }
        }).catch(httpError(() => {
          typeof failure === "function" && failure();
        }));
      };

      const config = {
        target: textareaRef.value,
        content_css: contentCss,
        entity_encoding: "raw",
        plugins: [
          "advlist lists charmap print preview anchor pagebreak image",
          "searchreplace wordcount visualblocks visualchars code fullscreen insertdatetime media nonbreaking",
          "directionality template paste textpattern table",
        ],
        language: "ko_KR",
        language_url: "/assets/lib/tinymce/custom/langs/ko_KR.js",
        toolbar: `undo redo bold | media | ${props.allowImage ? "image | " : ""}table `,
        toolbar_sticky: true,
        toolbar_sticky_offset: 0,
        toolbar_mode: "wrap",
        body_class: "edit-style editor simple-editor",
        extended_valid_elements: "area[shape|coords|href|target|onfocus]",
        forced_root_block: "p",
        statusbar: false,
        menubar: "false",
        height: props.height || 1000,
        automatic_uploads: true,
        paste_as_text: true,
        relative_urls: false,
        // placeholder: props.placeholder,
        media_dimensions: false,
        image_title: false,
        image_description: false,
        image_caption: true,
        file_picker_types: "image",
        table_default_attributes: {
          border: "0"
        },
        table_default_styles: {
          "border-collapse": "collapse",
          "width": "100%",
          "border-width": "2px 0",
          "border-style": "solid",
          "border-color": "#48494a"
        },
        images_upload_handler: editorImageUploadHandler,
        setup: function (editor) {
          let addEventTimer;
          let triggerClickTimer;

          const triggerClickUploadTab = () => {
            let uploadTab = $(".tox-dialog__body-nav-item").eq(1);
            if (uploadTab.length) {
              uploadTab.trigger("click");
              clearInterval(triggerClickTimer);
            }
          };

          const addEventHandler = () => {
            let imageToolbarButton = $("button[title=\"이미지 삽입/수정\"]");
            if (imageToolbarButton.length) {
              if (env.device === "mobile") {
                imageToolbarButton.on("touchstart", function () {
                  triggerClickTimer = setInterval(triggerClickUploadTab, 10);
                });
              } else {
                imageToolbarButton.on("click", function () {
                  triggerClickTimer = setInterval(triggerClickUploadTab, 10);
                });
              }

              clearInterval(addEventTimer);
            }
          };

          editor.on("input undo redo paste change keyup", () => {
            if (!props.maxLength || window.tinymce.activeEditor.plugins.wordcount.body.getCharacterCount() <= props.maxLength) {
              emit("update:value", editor.getContent());
            } else if (window.tinymce.activeEditor.plugins.wordcount.body.getCharacterCount() > props.maxLength) {
              editor.setContent(props.value);
              return store.commit("setSwingMessage", `${props.maxLength}자 이하로 입력해주세요`);
            }
          });

          editor.on("init", function () {
            let newLang = {"Caption": "이미지 설명", "Show caption": "이미지 설명 추가"};
            window.tinymce.addI18n("ko_KR", newLang);
            addEventTimer = setInterval(addEventHandler, 100);

            if (!editor.getContent()) {
              editor.getBody().setAttribute("data-placeholder", props.placeholder);
            }
          });

          editor.on("focus", function () {
            editor.getBody().removeAttribute("data-placeholder");
          });

          editor.on("blur", function () {
            if (editor.getContent() === "") {
              editor.getBody().setAttribute("data-placeholder", props.placeholder);
            }
          });

          editor.on("focusout", function () {
            if (((navigator.userAgent.toLowerCase()).indexOf("chrome") !== -1) && (window.tinymce.activeEditor.plugins.fullscreen.isFullscreen())) {
              window.tinymce.activeEditor.focus();
            }
          });
        },
        init_instance_callback: function (editor) {
          editor.on("ExecCommand", function (e) {
            if (e.command === "mceUpdateImage") {
              const figcaptions = editor.dom.select("figcaption");

              if (figcaptions) {
                $.each(figcaptions, function (index, element) {
                  if ("Caption" === $(element).text()) {
                    $(element).text("이미지 설명을 입력하세요.");
                  }
                });
              }
            }
          });
        }
      };

      if (widthRate != null) {
        config.width = widthRate;
      }

      if (mode) {
        delete config.selector;
        config.mode = "textareas";
      }

      if (fullscreen) {
        config.toolbar = "fullscreen | " + config.toolbar;
      }

      return config;
    };

    onBeforeUnmount(() => {
      window.tinymce.remove(state.instance);
    });

    onMounted(() => {
      store.dispatch("appendScript", {
        src: "/assets/lib/tinymce/tinymce.min.js",
        onEveryLoad() {
          window.tinymce.init(getTinymceConfig({
            contentCss: ["/assets/lib/font-awesome.min.css", "/assets/lib/tinymce/custom/css/edit.css", "/assets/lib/tinymce/custom/css/rewardEdit.css"],
            fullscreen: true
          })).then((instances) => {
            state.instance = instances[0];

            if (props.disabled) {
              state.instance.setMode("readonly"); // 에디터를 읽기 전용으로 설정
            } else {
              state.instance.setMode("design"); // 에디터를 활성화
            }
          });
        }
      });
    });

    return {component, state, textareaRef, computedValue};
  }
});
</script>

<style lang="scss">
.tox {
}
</style>