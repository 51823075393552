<template>
  <div class="step5 apply" :class="{skeleton: !loaded}">
    <div class="row">
      <div class="form-group col-md-4">
        <label class="subject font-sm">
          <span>진행자명</span>
        </label>
        <input type="text" class="form-control border-focus-point font-sm" :value="$store.state.account.memberName" disabled/>
      </div>
      <div class="form-group col-md-4">
        <label :for="`${component.name}UserMail`" class="subject font-sm">
          <span class="color-point">*</span>
          <span> 이메일</span>
        </label>
        <input type="email" class="form-control border-focus-point font-sm" :id="`${component.name}UserMail`" placeholder="ex) example@domain.com" v-model="form.new.userMail" @keyup.enter="save()"/>
      </div>
      <div class="form-group col-md-4">
        <label class="subject font-sm" :for="`${component.name}UserHp`">
          <span class="color-point">*</span>
          <span> 휴대전화</span>
        </label>
        <Phone :id="`${component.name}UserHp`" :value.sync="form.new.userHp" placeholder="ex) 01012345678" :enter="save"/>
      </div>
    </div>
    <div class="form-group">
      <label class="subject font-sm" :for="`${component.name}Address`">
        <span class="color-point">*</span>
        <span> 주소</span>
      </label>
      <Address :id="`${component.name}Address`" :zoneCode.sync="form.new.userPost" :address1.sync="form.new.userAddr1" :address2.sync="form.new.userAddr2" :enter="save"/>
    </div>
    <div class="form-group">
      <label class="subject font-sm" :for="`${component.name}Intro`">
        <span class="color-point">*</span>
        <span> 소개</span>
        <span class="intro-length">{{ `(${form.new.introduction ? form.new.introduction.trim().length : 0} / 170)` }}</span>
      </label>
      <textarea :id="`${component.name}Intro`" class="form-control border-focus-point font-sm" placeholder="진행자님을 170자 이내로 간략하게 소개해주세요." maxlength="170" v-model="form.new.introduction" @keyup.ctrl.enter="save()"></textarea>
    </div>
    <div class="form-group">
      <label class="subject font-sm">
        <span>웹 사이트(선택 입력)</span>
      </label>
      <div class="buttons">
        <div class="part" :class="[o.name, state.selects.indexOf(o.name) >= 0 ? 'selected' : '']" v-for="(o, idx) in state.options" :key="idx">
          <button class="btn btn-default" @click="toggleOption(o.name)" :title="`${state.selects.indexOf(o.name) >= 0 ? '해제' : '선택'}하시려면 클릭하세요.`">
            <span class="img" :style="{backgroundImage: `url('${state.selects.indexOf(o.name) >=0  ? o.icoSelected : o.ico}')`}"></span>
          </button>
          <div class="font-xs">{{ o.title }}</div>
        </div>
      </div>
    </div>
    <div class="form-group" v-if="state.selects.indexOf('home') >= 0">
      <label for="homeUrl" class="subject font-sm">
        <span>홈페이지</span>
      </label>
      <input type="text" class="form-control border-focus-point font-sm" id="homeUrl" placeholder="ex) https://ohmycompany.com/" v-model="form.new.homeUrl" @keyup.enter="save()"/>
    </div>
    <div class="form-group" v-if="state.selects.indexOf('facebook') >= 0">
      <label for="facebook" class="subject font-sm">
        <span>페이스북</span>
      </label>
      <input type="text" class="form-control border-focus-point font-sm" id="facebook" placeholder="ex) https://www.facebook.com/omc2012" v-model="form.new.facebook" @keyup.enter="save()"/>
    </div>
    <div class="form-group" v-if="state.selects.indexOf('blog') >= 0">
      <label for="blog" class="subject font-sm">
        <span>블로그</span>
      </label>
      <input type="text" class="form-control border-focus-point font-sm" id="blog" placeholder="ex) https://post.naver.com/omcofficial" v-model="form.new.blog" @keyup.enter="save()"/>
    </div>
    <div class="form-group" v-if="state.selects.indexOf('instagram') >= 0">
      <label for="insta" class="subject font-sm">
        <span>인스타그램</span>
      </label>
      <input type="text" class="form-control border-focus-point font-sm" id="insta" placeholder="ex) https://instagram.com/oh_mycompany" v-model="form.new.insta" @keyup.enter="save()"/>
    </div>
    <div class="picture" title="프로필 사진">
      <MyPicture :id="`${component.name}Picture`"/>
      <div class="desc font-xs">
        <span class="color-point">* </span>
        <span class="txt">프로필 사진 (비율 1:1)</span>
      </div>
    </div>
  </div>
</template>

<script>
import MyPicture from "@/components/MyPicture";
import Address from "@/components/Address";
import Phone from "@/components/Phone";
import {defineComponent, reactive} from "@vue/composition-api";
import lib from "@/scripts/lib";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Date from "@/components/Date";
import Number from "@/components/Number";
import http from "@/scripts/http";
import env from "@/scripts/env";

function Component(initialize) {
  this.name = "pageApplyRewardMakerstep5";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    form: Object,
    files: Object,
    validators: Object,
    save: Function,
    warn: Function,
    loaded: Boolean
  },
  components: {Number, Date, MyPicture, Address, Phone},
  setup(props) {
    const component = new Component(() => {
      const sites = [
        {key: "home", value: props.form.new.homeUrl},
        {key: "facebook", value: props.form.new.facebook},
        {key: "blog", value: props.form.new.blog},
        {key: "instagram", value: props.form.new.insta},
      ];

      for (let site of sites) {
        site.value
        && state.options.some(o => o.name === site.key)
        && state.selects.push(site.key);
      }

      props.validators[5] = async () => {
        const profileFilePath = await new Promise((resolve, reject) => {
          http.get(store.getters.profileImageUrl()).then((res) => {
            resolve(res.request?.responseURL);
          }).catch(() => {
            reject();
          });
        });

        if (!profileFilePath || profileFilePath.endsWith(env.profileFileDefaultPath)) {
          return props.warn("프로필 사진을 등록해주세요.");
        } else if (!props.form.new.userMail) {
          return props.warn("이메일 주소를 입력해주세요.", `${component.name}UserMail`);
        } else if (!lib.isValidEmail(props.form.new.userMail)) {
          return props.warn("이메일 주소를 정확히 입력해주세요.", `${component.name}UserMail`);
        } else if (!props.form.new.userHp) {
          return props.warn("휴대전화 번호를 입력해주세요.", `${component.name}UserHp`);
        } else if (!lib.isValidMobileNum(props.form.new.userHp)) {
          return props.warn("휴대전화 번호를 정확히 입력해주세요.", `${component.name}UserHp`);
        } else if (!props.form.new.userAddr1) {
          return props.warn("주소를 입력해주세요.", `${component.name}Address`);
        } else if (!props.form.new.userAddr2) {
          return props.warn("상세 주소를 입력해주세요.", `${component.name}Address`);
        } else if (!props.form.new.introduction?.trim()) {
          return props.warn("소개 내용을 입력해주세요.", `${component.name}Intro`);
        } else if (props.form.new.introduction?.trim().length > 170) {
          return props.warn("소개 내용은 170자 이내로 입력해주세요.", `${component.name}Intro`);
        } else if (props.form.new.homeUrl && !lib.isValidUrl(props.form.new.homeUrl)) {
          return props.warn("홈페이지 주소를 정확히 입력해주세요.", "homeUrl");
        } else if (props.form.new.facebook && !lib.isValidUrl(props.form.new.facebook)) {
          return props.warn("페이스북 주소를 정확히 입력해주세요.", "facebook");
        } else if (props.form.new.blog && !lib.isValidUrl(props.form.new.blog)) {
          return props.warn("블로그 주소를 정확히 입력해주세요.", "blog");
        } else if (props.form.new.insta && !lib.isValidUrl(props.form.new.insta)) {
          return props.warn("인스타그램 주소를 정확히 입력해주세요.", "insta");
        }

        return true;
      };
    });

    const state = reactive({
      selects: [],
      agree: false,
      options: [
        {name: "home", title: "홈페이지", ico: "/assets/ico/page.apply.reward.step1.home.svg", icoSelected: "/assets/ico/page.apply.reward.step1.home.selected.svg"},
        {name: "facebook", title: "페이스북", ico: "/assets/ico/page.apply.reward.step1.facebook.svg", icoSelected: "/assets/ico/page.apply.reward.step1.facebook.selected.svg"},
        {name: "blog", title: "블로그", ico: "/assets/ico/page.apply.reward.step1.blog.svg", icoSelected: "/assets/ico/page.apply.reward.step1.blog.selected.svg"},
        {name: "instagram", title: "인스타그램", ico: "/assets/ico/page.apply.reward.step1.instagram.svg", icoSelected: "/assets/ico/page.apply.reward.step1.instagram.selected.svg"},
      ]
    });

    const toggleOption = (name) => {
      if (state.selects.indexOf(name) >= 0) {
        state.selects.splice(state.selects.findIndex(s => s.name === name), 1);
      } else {
        state.selects.push(name);
      }
    };

    const clearInput = (e) => {
      e.currentTarget.querySelector("input").value = "";
    };

    const setFile = (e, name) => {
      if (e.target.files.length && props.files.new[name] !== undefined) {
        props.files.new[name]?.fileSeq && props.files.deletes.push(props.files.new[name]);
        props.files.new[name] = e.target.files[0];
      }
    };

    const delFile = (fileCode) => {
      props.files.new[fileCode].fileCode && props.files.deletes.push(props.files.new[fileCode]);
      props.files.new[fileCode] = null;
    };

    return {component, state, toggleOption, clearInput, setFile, delFile};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.apply";

.step5 {
  position: relative;

  .form-group {
    .intro-length {
      font-size: $px11;
      padding-left: $px10;
    }

    textarea {
      min-height: $px120;
    }

    .buttons {
      padding-top: $px5;

      .part {
        display: inline-block;
        width: $px64;
        text-align: center;
        margin-right: $px10;
        transition: color 0.18s;
        position: relative;

        button {
          width: $px52;
          height: $px52;
          padding: $px4 0 0 0;
          margin-bottom: $px8;
          border-radius: 50%;
          box-shadow: none;
          position: relative;

          .img {
            height: $px17;
            width: $px17;
          }

          &:after {
            content: "\f096";
            display: inline-block;
            position: absolute;
            top: $px5;
            right: $px1;
            color: #ddd;
            height: $px13;
            line-height: 0.9;
            background: #fff;
            border-radius: $px4;
            font-family: "FontAwesome";
          }
        }

        i {
          position: absolute;
          top: $px5;
          right: $px1;
          color: #ddd;
          height: $px13;
          line-height: 0.9;
          background: #fff;
          border-radius: $px4;
        }

        &.selected {
          color: $colorPoint;

          button {
            border-color: $colorPoint;

            &:after {
              content: "\f046";
              color: $colorPoint;
            }
          }

          i {
            color: inherit;
          }
        }

        &.blog button .img {
          width: $px16;
          height: $px16;
        }

        &:last-child {
          margin-right: 0;
        }
      }
    }
  }

  .picture {
    position: absolute;
    top: $px-90;
    right: $px8;

    .my-picture {
      width: $px80;
      height: $px80;
    }

    .desc {
      position: absolute;
      top: $px-36;
      left: $px-145;
      padding: $px11;
      border: $px1 solid #eee;
      background: #f8f9fa;
      border-radius: $px15 $px15 0 $px15;

      .txt2 {
        display: none;
      }
    }
  }

  &.skeleton {
    .picture::v-deep {
      .my-picture {
        .img {
          @include skeleton;
        }

        .actions {
          label {
            @include skeleton;
          }
        }
      }

      .desc {
        span {
          @include skeleton;
        }
      }
    }

    .subject {
      @include skeleton;

      > span {
        visibility: hidden;
      }
    }

    .form-group::v-deep {
      input {
        @include skeleton;
      }

      button {
        @include skeleton;

        .img {
          display: none;
        }
      }
    }

    .buttons .part div {
      @include skeleton;
    }
  }

  @media(max-width: 767px) {
    .picture {
      top: $px-100;
      right: $px-20;

      .desc {
        top: $px-18;
        left: $px-142;
      }
    }
  }
}
</style>