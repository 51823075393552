<template>
  <div class="step2 apply">
    <div class="row float-right actions mr-0">
      <button class="btn btn-point font-sm pointer-u" @click="remove(idx1)" v-if="computedRewardItems.length > 1">- 리워드 삭제</button>
      <button class="btn btn-point font-sm pointer-u" @click="insert(idx1)">+ 리워드 추가</button>
    </div>
    <div class="table-responsive" v-for="(item1, idx1) in computedRewardItems" :key="idx1">
      <table class="table mt-10">
        <tbody>
        <tr>
          <th>
            <span class="color-point">*</span>
            <span class="subject font-sm"> 리워드명</span>
          </th>
          <td colspan="3">
            <input :id="`${component.name}RewardTitle${idx1}`" type="text" class="form-control border-focus-point font-sm" placeholder="사탕수수로 만든 친환경 텀블러 1개" v-model="item1.title" @keyup.enter="save()" maxlength="25"/>
          </td>
        </tr>
        <tr>
          <th>
            <span class="color-point">*</span>
            <span class="subject font-sm"> 리워드 금액</span>
          </th>
          <td colspan="3">
            <Number :id="`${component.name}RewardAmt${idx1}`" placeholder="(단위:  원) " :allowZero="false" :value.sync="item1.rewardAmt" :maxlength="20"/>
          </td>
        </tr>
        <tr>
          <th>
            <span class="color-point">*</span>
            <span class="font-sm"> 리워드 수량</span>
          </th>
          <td>
            <label :for="`${component.name}RewardQtyLimitNoLimit${idx1}`">
              <input type="radio" :id="`${component.name}RewardQtyLimitNoLimit${idx1}`" :name="`${component.name}RewardQtyLimit${idx1}`" :value="false" v-model="item1.rewardQtyLimit" @change="onRewardQtyLimitChange(idx1)">
              <span class="font-sm"> 제한 없음</span>
            </label>
            <label :for="`${component.name}RewardQtyLimitLimited${idx1}`">
              <input type="radio" :id="`${component.name}RewardQtyLimitLimited${idx1}`" :name="`${component.name}RewardQtyLimit${idx1}`" :value="true" v-model="item1.rewardQtyLimit" @change="onRewardQtyLimitChange(idx1)">
              <span class="font-sm"> 수량 제한</span>
            </label>
          </td>
          <th>
            <span class="font-sm"> 개수</span>
          </th>
          <td>
            <Number :id="`${component.name}test`" placeholder="예) 100" :allowZero="false" :value.sync="item1.rewardQty" :disabled="!item1.rewardQtyLimit" :maxlength="10"/>
          </td>
        </tr>
        <tr>
          <th>
            <span class="color-point">*</span>
            <span class="subject font-sm"> 리워드 옵션</span>
          </th>
          <td class="font-sm" colspan="3">
            <label :for="`${component.name}RewardSelFlag1${idx1}`">
              <input type="radio" :id="`${component.name}RewardSelFlag1${idx1}`" :name="`${component.name}RewardSelFlag${idx1}`" value="N" v-model="item1.rewardSelFlag" :checked="item1.rewardSelFlag === 'N'">
              <span> 사용 안함</span>
            </label>
            <label :for="`${component.name}RewardSelFlag2${idx1}`">
              <input type="radio" :id="`${component.name}RewardSelFlag2${idx1}`" :name="`${component.name}RewardSelFlag${idx1}`" value="Y" v-model="item1.rewardSelFlag">
              <span> 객관식</span>
            </label>
            <label :for="`${component.name}RewardSelFlag3${idx1}`">
              <input type="radio" :id="`${component.name}RewardSelFlag3${idx1}`" :name="`${component.name}RewardSelFlag${idx1}`" value="Y" v-model="item1.rewardSelFlag">
              <span> 주관식</span>
            </label>
          </td>
        </tr>
        </tbody>
      </table>
      <div class="col-lg-4 col-md-6 pl-0 mt-10">
        <div class="form-group">
          <label class="subject font-sm" :for="`${component.name}RewardExpectText${idx1}`">
            <span class="color-point">*</span>
            <span> 리워드 예상 제공일</span>
          </label>
          <Date componentNameSuffix="S" :id="`${component.name}RewardExpectText${idx1}`" class="font-sm" placeholder="예상 제공일을 선택해 주세요." :value.sync="item1.rewardExpectText"/>
        </div>
      </div>
    </div>
    <div class="row">
      <div class="col-lg-3 col-md-6">
        <div class="form-group">
          <label class="subject font-sm" :for="`${component.name}DeliveryAmt`">
            <span class="color-point">*</span>
            <span> 리워드 배송비</span>
          </label>
          <Number :id="`${component.name}DeliveryAmt`" placeholder="(단위: 원)" :allowZero="true" :value.sync="form.new.deliveryAmt" :enter="save" :maxlength="7"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import lib from "@/scripts/lib";
import Number from "@/components/Number";
import mixin from "@/scripts/mixin";
import Date from "@/components/Date.vue";

function Component(initialize) {
  this.name = "pageApplyRewardMakerstep2";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    form: Object,
    files: Object,
    validators: Object,
    save: Function,
    warn: Function,
  },
  components: {Date, Number},
  setup(props) {
    const component = new Component(() => {
      props.validators[2] = () => {
        if (["", undefined, null].includes(props.form.new.deliveryAmt)) {
          return props.warn("리워드 배송비를 입력해주세요.", `${component.name}DeliveryAmt`);
        }

        const displayOrder1Arr = [];

        for (let i in computedRewardItems.value) {
          const rewardOptionItems = computedRewardItems.value[i].rewardOptionItems.filter(i => i.delFlag !== "Y");

          computedRewardItems.value[i].title = computedRewardItems.value[i].title?.trim();
          computedRewardItems.value[i].rewardExpectText = computedRewardItems.value[i].rewardExpectText?.trim();

          if (!computedRewardItems.value[i].rewardAmt) {
            return props.warn("금액을 입력해주세요.", `${component.name}RewardAmt${i}`);
          } else if (!computedRewardItems.value[i].title) {
            return props.warn("리워드 구성을 입력해주세요.", `${component.name}RewardTitle${i}`);
          } else if (computedRewardItems.value[i].rewardQtyLimit && !computedRewardItems.value[i].rewardQty
              && (computedRewardItems.value[i].rewardSelFlag !== "Y" || !rewardOptionItems.length)) {
            return props.warn("리워드 개수를 입력해주세요.", `${component.name}RewardQty${i}`);
          }

          if (computedRewardItems.value[i].rewardSelFlag === "N") {
            computedRewardItems.value[i].rewardOptionItems = [];
          }

          if (computedRewardItems.value[i].rewardSelFlag === "Y" && rewardOptionItems.length) {
            const displayOrder2Arr = [];

            for (let j in rewardOptionItems) {
              if (!rewardOptionItems[j].rewardOptionText) {
                return props.warn("선택 옵션을 입력해주세요.", `${component.name}rewardOptionText${i}${j}`);
              } /*else if (computedRewardItems.value[i].rewardQtyLimit && rewardOptionItems[j].rewardOptionQty <= 0) {
                return props.warn("개수를 입력해주세요.", `${component.name}rewardOptionQty${i}${j}`);
              }*/ else if (displayOrder2Arr.includes(rewardOptionItems[j].displayOrder)) {
                return props.warn("중복된 순서 값이 있습니다.", `${component.name}DisplayOrder${i}${j}`);
              }

              displayOrder2Arr.push(rewardOptionItems[j].displayOrder);
            }
          }

          if (!computedRewardItems.value[i].rewardExpectText) {
            return props.warn("리워드 예상 제공일을 입력해주세요.", `${component.name}RewardExpectText${i}`);
          } else if (displayOrder1Arr.includes(computedRewardItems.value[i].displayOrder)) {
            return props.warn("중복된 노출 순서가 있습니다.", `${component.name}DisplayOrder${i}`);
          }

          displayOrder1Arr.push(computedRewardItems.value[i].displayOrder);
        }

        setRewardQtyFromItemsSum();
        return true;
      };
    });

    const state = reactive({
      applyAll: false
    });

    const computedRewardItems = computed(() => {
      return props.form.new.rewardItems.filter(i => i.delFlag !== "Y");
    });

    const insert = (idx) => {
      const rewardItem = lib.getRenewed(props.form.default.rewardItem);
      rewardItem.rewardSeq = null;
      rewardItem.rewardQtyLimit = false;

      if (state.applyAll) {
        rewardItem.rewardExpectText = computedRewardItems.value[0].rewardExpectText;
      }

      idx = props.form.new.rewardItems.findIndex(i => i === computedRewardItems.value[idx]);
      props.form.new.rewardItems.splice(idx + 1, 0, rewardItem);
      sort();

      setTimeout(() => {
        rewardItem.rewardSeq = "";
      });
    };

    const pushRewardOptionItems = (idx1) => {
      const rewardOptionItem = lib.getRenewed(props.form.default.rewardOptionItem);
      rewardOptionItem.displayOrder = (computedRewardItems.value[idx1].rewardOptionItems.filter(i => i.delFlag !== "Y").length + 1).toString();
      computedRewardItems.value[idx1].rewardOptionItems.push(rewardOptionItem);
      setRewardQtyFromItemsSum();
    };

    const setRewardQtyFromItemsSum = () => {
      for (let i in computedRewardItems.value) {
        const rewardOptionItems = computedRewardItems.value[i].rewardOptionItems.filter(i => i.delFlag !== "Y");

        if (computedRewardItems.value[i].rewardSelFlag === "Y" && rewardOptionItems.length && !computedRewardItems.value[i].rewardQtyLimit) {
          const rewardOptionItemsQtySum = rewardOptionItems.map(i => window.Number(i.rewardOptionQty || 0)).reduce((sum, x) => sum + x);
          computedRewardItems.value[i].rewardQty = rewardOptionItemsQtySum;
        }
      }
    };

    const remove = (idx) => {
      computedRewardItems.value[idx].delFlag = "Y";
      sort();
    };

    const removeRewardOptionItem = (idx1, idx2) => {
      const rewardOptionItems = computedRewardItems.value[idx1].rewardOptionItems.filter(i => i.delFlag !== "Y");
      rewardOptionItems[idx2].delFlag = "Y";

      for (let i in rewardOptionItems) {
        const rewardOptionItem = rewardOptionItems[i];
        const rewardOptionItemsFilteredLen = rewardOptionItems.filter(i => i.delFlag !== "Y").length;

        if (rewardOptionItem.delFlag !== "Y" && rewardOptionItemsFilteredLen < rewardOptionItem.displayOrder) {
          rewardOptionItem.displayOrder = rewardOptionItemsFilteredLen;
        }
      }

      setRewardQtyFromItemsSum();
    };

    const sort = () => {
      for (let i = 0; i < computedRewardItems.value.length; i += 1) {
        computedRewardItems.value[i].displayOrder = (i + 1).toString();
      }
    };

    const onRewardQtyLimitChange = (idx) => {
      if (!computedRewardItems.value[idx].rewardQtyLimit) {
        computedRewardItems.value[idx].rewardQty = null;
        computedRewardItems.value.forEach((item) => {
          item.rewardOptionItems.filter(i => i.delFlag !== "Y").forEach(i => i.rewardOptionQty = 0);
        });
      } else {
        setRewardQtyFromItemsSum();
      }
    };

    const onRewardExpectTextChange = () => {
      for (let i in computedRewardItems.value) {
        if (computedRewardItems.value[i].rewardExpectText !== computedRewardItems.value[0].rewardExpectText) {
          state.applyAll = false;
          break;
        }
      }
    };

    const onApplyAllChange = () => {
      if (state.applyAll) {
        for (let i in computedRewardItems.value) {
          computedRewardItems.value[i].rewardExpectText = computedRewardItems.value[0].rewardExpectText;
        }
      }
    };

    return {
      component
      , state
      , computedRewardItems
      , insert
      , pushRewardOptionItems
      , setRewardQtyFromItemsSum
      , remove
      , removeRewardOptionItem
      , onRewardQtyLimitChange
      , onRewardExpectTextChange
      , onApplyAllChange
    };
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.apply";

.step2 {
  position: relative;

  .actions button {
    margin-left: $px5;
  }

  .table-responsive {
    padding-bottom: $px20;

    table {
      border: 1px solid $colorBorder;

      tr {
        th, td {
          padding: $px10, 0;
          border-bottom: 1px solid $colorBorder;
          vertical-align: center;
        }

        th {
          //width: $px140;
          background: $colorBackground;
          font-weight: 400;
          vertical-align: middle;
          white-space: nowrap;
        }

        td {
          vertical-align: middle;

          label {
            margin-right: $px10;
            margin-bottom: 0;
          }

          input[type=radio], span {
            vertical-align: middle;
          }
        }
      }
    }
  }

  @media(min-width: 992px) {
    > .rewards > li {
      > .wrapper .block.reward .reward-option-items .row .form-group {
        margin-bottom: 0;
      }
    }
  }
}
</style>