<template>
  <div class="step1 apply">
    <div class="row">
      <div class="col-lg-6">
        <div class="form-group">
          <label :for="`${component.name}ProjectName`" class="subject font-sm">
            <span class="color-point">*</span>
            <span> 프로젝트 제목</span>
            <span class="font-xs"> ({{ form.new.projectName ? form.new.projectName.trim().length : 0 }}/30)</span>
          </label>
          <input :id="`${component.name}ProjectName`" type="text" class="form-control border-focus-point font-sm mb-10" maxlength="30" :value="form.new.projectName" @input="form.new.projectName = $event.target.value"
                 @keyup.enter="save()"/>
        </div>
      </div>
      <div class="col-lg-6">
        <div class="form-group">
          <label :for="`${component.name}SimpleText`" class="subject font-sm">
            <span class="color-point">*</span>
            <span> 프로젝트 간략 소개</span>
            <span class="font-xs"> ({{ form.new.simpleText ? form.new.simpleText.length : 0 }}/20)</span>
          </label>
          <input :id="`${component.name}SimpleText`" type="text" class="form-control border-focus-point font-sm" maxlength="20" :value="form.new.simpleText" @input="form.new.simpleText = $event.target.value" @keyup.enter="save()"/>
        </div>
      </div>
    </div>
    <div class="form-group file">
      <div class="font-sm clearfix">
        <label class="subject float-left mb-0" :for="`${component.name}MainImgFile`">
          <span class="color-point">*</span>
          <span> 프로젝트 대표 이미지 </span>
          <div class="font-xs color-secondary">(권장 비율: 4 : 3 / 권장 크기: {{ $definitions.thumbnail.width }} * {{ $definitions.thumbnail.height }}px / 형식: jpg, png / 최대 크기: {{ $definitions.limits.maxFileSize }})</div>
        </label>
        <div class="right-act">
          <label class="mb-0" @click.stop="clearInput($event)">
            <a class="pointer">파일 올리기</a>
            <input :id="`${component.name}MainImgFile`" type="file" :accept="$definitions.limits.fileExtensions.imgStr" class="hide" @change="setFile($event, 'mainImgFile')"/>
          </label>
        </div>
      </div>
      <ul class="files tight" v-if="files.new.mainImgFile">
        <li class="font-sm pointer" :type="files.new.mainImgFile.type" @click="$store.dispatch('previewFile', files.new.mainImgFile)" title="클릭 시 미리보기">
          <div>{{ files.new.mainImgFile.fileSeq ? files.new.mainImgFile.fileOrigName : files.new.mainImgFile.name }}</div>
          <span class="times" title="삭제" @click.stop="delFile('mainImgFile')">&times;</span>
        </li>
      </ul>
      <div class="preview" v-if="files.new.mainImgFile">
        <div class="font-sm title">
          <div class="alert alert-warning" v-if="!state.preview.recommendRatio">올려주신 이미지가 권장 비율과 다릅니다. 일부 내용이 다르게 보일 수 있습니다.</div>
        </div>
        <div class="wrapper" :class="{'skeleton' : !state.preview.loaded}">
          <img :id="`${component.name}MainImgPreviewFrame`" class="frame" src="/assets/img/common.ratio.43.png"/>
          <div class="inner">
            <img :id="`${component.name}MainImgPreviewOrigin`" class="origin" :src="getPreviewImage(files.new.mainImgFile)"/>
          </div>
          <div class="letter-box left"></div>
          <div class="letter-box right"></div>
        </div>
      </div>
    </div>
    <div class="form-group images file">
      <div class="font-sm clearfix">
        <label class="subject float-left mb-0" @click="setFiles()">
          <span>프로젝트 서브 이미지</span>
          <span class="font-xs"> ({{ files.new.subImgFiles.length }}/{{ maxSubImgFilesCnt }})</span>
          <div class="font-xs color-secondary">(대표 이미지와 함께 슬라이드 형태로 노출 / 형식: jpg, png)</div>
        </label>
        <div class="action">
          <a class="pointer" @click="setFiles()">파일 올리기</a>
        </div>
      </div>
      <ul class="files tight">
        <li class="font-sm pointer" data-type="image" :data-code="f.fileCode" v-for="(f, idx) in files.new.subImgFiles" :key="idx" @click="$store.dispatch('previewFile', f)" title="클릭 시 미리보기">
          <div>{{ f.fileSeq ? f.fileOrigName : f.name }}</div>
          <span class="pointer times" title="삭제" @click.stop="delSubImgFile(idx)">&times;</span>
        </li>
      </ul>
    </div>
    <div class="form-group">
      <div class="font-sm clearfix">
        <label class="subject float-left">
          <span> 메인 동영상 사용 유무</span>
          <div class="font-xs color-secondary">(사용 선택 시 상세 페이지 대표 이미지에 동영상만 노출)</div>
        </label>
        <div class="right-act">
          <div class="form-check">
            <input type="checkbox" class="form-check-input" :id="`${component.name}VideoFlag`" v-model="state.video" @change="setVideoFlag()"/>
            <label class="form-check-label" :for="`${component.name}VideoFlag`">네, 사용합니다.</label>
          </div>
        </div>
      </div>
      <input :id="`${component.name}VideoUrl`" type="text" class="form-control border-focus-point font-sm" placeholder="동영상 URL을 입력해주세요." v-model="form.new.videoUrl" @keyup.enter="save()" v-if="state.video"/>
    </div>
    <div class="form-group editor wider">
      <label class="subject font-sm">
        <span class="color-point">*</span>
        <span> 프로젝트 상세 소개</span>
      </label>
      <div class="right-act">
        <span class="pointer color-anchor font-xs" @click="$store.commit('openModal', {name:'EditorGuide'})">에디터 사용법</span>
        <span class="ml-3 pointer color-anchor font-xs" @click="openAI()">챗GPT로 초안 생성하기</span>
      </div>
      <div class="wrapper">
        <TinyEditor :id="`${component.name}ProjectInfo`" :value.sync="form.new.projectInfo" ref="editorRef" :key="keys.editor"/>
      </div>
    </div>
    <div class="alert">
      <ul class="tight">
        <li>
          <p>
            <span>※ 프로젝트 소개 입력 방식은 아래 두 가지입니다.</span>
            <br/>
            <span>- 블로그형(이미지-글-이미지-글 형태) </span>
            <a class="ml-1 underline" href="/reward/7202" target="_blank" rel="noopener noreferrer">예시 바로가기> </a>
            <br/>
            <span>- 웹페이지형(하나의 이미지 형태, 자체 제작)</span>
            <a class="ml-1 underline" href="/reward/6410" target="_blank" rel="noopener noreferrer">예시 바로가기> </a>
          </p>
        </li>
        <li>
          <p>※ 문단과 문단 사이 간격이 넓습니다. 간격을 줄이시려면 <span class="color-danger">shift + enter</span> 를 사용해주세요.</p>
        </li>
        <li>
          <p>※ 문장에 적용한 효과를 삭제하려면 해당 문장을 선택한 뒤 적용한 효과 버튼을 한번 더 눌러주세요.</p>
        </li>
        <li>
          <p>※ 타 파일에서 문장을 복사, 붙여넣기 전에는 메모장 등에 한번 거쳐서 입력해주세요.</p>
        </li>
        <li>
          <p>※ 최대 노출 가능한 이미지 가로 사이즈는 600px 입니다.</p>
        </li>
        <li>
          <p>※ 권장 비율을 지키지 않았을 경우 이미지 일부가 잘린 채 노출되는 등 다르게 보일 수 있습니다.</p>
        </li>
        <li>
          <p>※ 이미지 삽입을 통해 GIF 파일 입력이 가능합니다.</p>
        </li>
        <li>
          <div>* 프로젝트 스토리 작성이 어려우시면, 우측 상단의 <a :href="$definitions.urls.rewardStoryGuide" target="_blank" rel="noopener noreferrer">스토리 가이드</a>를 참고해보세요.</div>
        </li>
      </ul>
    </div>
    <div class="form-group hashtag">
      <label :for="`${component.name}Hashtag`" class="subject font-sm">
        <span>연관 해시태그 (최대 5개, 프로젝트의 노출 빈도를 높여줍니다)</span>
      </label>
      <input :id="`${component.name}Hashtag`" type="text" class="form-control border-focus-point font-sm" placeholder="입력 후 엔터를 눌러주세요." @blur="hashtagFocusOut" @keyup="setHashtag($event)" ref="hashtagRef"/>
      <div class="suggestions" v-if="state.tagSuggestions.length > 0">
        <div class="suggestions-item" v-for="tag in state.tagSuggestions" :key="tag" @mousedown="clickHashTag(tag)">
          <span>{{ tag }}</span>
        </div>
      </div>
      <ul class="tight">
        <li v-for="(t, idx) in state.tags" :key="idx">
          <a class="font-xs">#{{ t }}</a>
          <span title="삭제" @click="removeHashtag(idx)">&times;</span>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {defineComponent, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import TinyEditor from "@/components/TinyEditor";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import definitions from "@/scripts/definitions";

function Component(initialize) {
  this.name = "pageApplyRewardMakerStep1";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    form: Object,
    files: Object,
    validators: Object,
    save: Function,
    warn: Function,
    keys: Object,
  },
  components: {TinyEditor},
  setup(props) {
    const component = new Component(() => {
      if (props.form.new.tag) {
        for (let t of lib.getSplit(props.form.new.tag, ["\r\n", "\r", "\n", ","])) {
          t && t.trim() && state.tags.push(t.replaceAll("#", "").trim());
        }
      }

      state.video = props.form.new.videoFlag === "Y";

      props.validators[1] = () => {
        if (!props.form.new.projectName?.trim()) {
          return props.warn("프로젝트 제목을 입력해주세요.", `${component.name}ProjectName`);
        } else if (!props.form.new.simpleText?.trim()) {
          return props.warn("프로젝트 간략 소개 내용을 입력해주세요.", `${component.name}SimpleText`);
        } else if (!props.files.new.mainImgFile) {
          return props.warn("프로젝트 대표 이미지를 올려주세요.");
        }

        if (props.files.new.mainImgFile.size && (props.files.new.mainImgFile.size > maxFileSize || props.files.new.mainImgFile.size > maxRequestSize)) {
          return props.warn(`프로젝트 대표 이미지의 용량이 너무 큽니다. ${definitions.limits.maxFileSize} 이하로 올려주세요.`);
        }

        if (props.files.new.subImgFiles.length) {
          let subImgFilesTotLen = 0;

          for (let i in props.files.new.subImgFiles) {
            if (props.files.new.subImgFiles[i].size) {
              if (props.files.new.subImgFiles[i].size > maxFileSize) {
                return props.warn(`프로젝트 서브 이미지의 용량이 너무 큽니다. ${definitions.limits.maxFileSize} 이하로 올려주세요.`);
              }

              subImgFilesTotLen += props.files.new.subImgFiles[i].size;
            }
          }

          if (subImgFilesTotLen > maxRequestSize) {
            return props.warn(`프로젝트 서브 이미지의 용량이 너무 큽니다. 크기 조정 후 다시 업로드해주세요.`);
          }
        }

        if (!props.form.new.projectInfo) {
          window.tinyMCE.activeEditor.focus();
          return store.commit("setSwingMessage", "프로젝트 상세 소개 내용을 입력해주세요.");
        } else if (props.form.new.tag && props.form.new.tag.split(",").length > 5) {
          return props.warn("연관 해시태그는 5개까지 입력 가능합니다.", `${component.name}Hashtag`);
        }

        if (state.video) {
          if (!props.form.new.videoUrl?.trim()) {
            return props.warn("동영상 URL을 입력해주세요.", `${component.name}VideoUrl`);
          } else if (!lib.isValidUrl(props.form.new.videoUrl)) {
            return props.warn("동영상 URL 값이 유효하지 않습니다.", `${component.name}VideoUrl`);
          } else if (!props.form.new.videoUrl.startsWith("https://")) {
            props.form.new.videoUrl = "https://" + props.form.new.videoUrl.replace("http://", "");
          }
        }

        return true;
      };

      http.get("/api/reward/categories").then(({data}) => {
        state.categories = data.body;
      });

      setPreviewRatio();
    });

    const state = reactive({
      video: false,
      preview: {
        loaded: false,
        recommendRatio: false,
      },
      categories: [],
      tags: [],
      tagSuggestions: [],
    });

    const hashtagRef = ref();
    const editorRef = ref();
    const maxSubImgFilesCnt = 4;

    const maxFileSize = lib.getMbToByte(definitions.limits.maxFileSize.replace("MB", ""));
    const maxRequestSize = lib.getMbToByte(definitions.limits.maxRequestSize.replace("MB", ""));

    const setVideoFlag = () => {
      props.form.new.videoFlag = state.video ? "Y" : "N";
    };

    const syncHashtag = () => {
      props.form.new.tag = state.tags.map(t => "#" + t + "\r\n").join("");
    };

    const fetch = async (keyword) => {
      http.get(`/api/reward/projects/tags?keyword=${keyword}`).then(({data}) => {
        state.tagSuggestions = data.body;
      });
    };

    const clickHashTag = (newHashtag) => {
      if (!newHashtag) {
        return;
      } else if (state.tags.includes(newHashtag)) {
        return store.commit("setSwingMessage", "입력하신 해시태그가 이미 존재합니다.");
      }

      if (state.tags.length >= 5) {
        store.commit("setSwingMessage", "해시태그는 5개까지 입력 가능합니다.");
      } else {
        if (state.tags.length === 4) {
          state.tagSuggestions = [];
          hashtagRef.value.value = "";
        }

        state.tagSuggestions = state.tagSuggestions.filter(tag => tag !== newHashtag);

        if (state.tagSuggestions.length === 0) {
          hashtagRef.value.value = "";
        }

        state.tags.push(newHashtag);
        syncHashtag();
      }
    };

    const setHashtag = (e) => {
      const keyword = hashtagRef.value.value;

      if (e.key === "Backspace") {
        state.tagSuggestions = [];
      } else if (keyword.length >= 1) {
        fetch(keyword);
      }

      if ([13, 188].includes(e.keyCode)) {
        const newHashtag = e.target.value?.replaceAll(",", "")?.replaceAll("#", "")?.trim();

        if (!newHashtag) {
          return;
        } else if (state.tags.includes(newHashtag)) {
          return store.commit("setSwingMessage", "입력하신 해시태그가 이미 존재합니다.");
        }

        if (state.tags.length >= 5) {
          store.commit("setSwingMessage", "해시태그는 5개까지 입력 가능합니다.");
        } else {
          hashtagRef.value.value = "";
          state.tags.push(newHashtag);
          syncHashtag();
        }

        state.tagSuggestions = [];
        e.target.value = "";
      }
    };

    const removeHashtag = (idx) => {
      state.tags.splice(idx, 1);
      syncHashtag();
    };

    const hashtagFocusOut = (e) => {
      e.target.value = "";
      state.tagSuggestions = [];
    };

    const clearInput = (e) => {
      e.currentTarget.querySelector("input").value = "";
    };

    const setFile = (e, name) => {
      if (!store.getters.isAllowedExtension(e.target, "image")) {
        return;
      }

      if (e.target.files.length && props.files.new[name] !== undefined) {
        if (e.target.files[0].size > maxFileSize) {
          return store.commit("setSwingMessage", `파일의 용량이 너무 큽니다. ${definitions.limits.maxFileSize} 이하로 올려주세요.`);
        }

        props.files.new[name]?.fileSeq && props.files.deletes.push(props.files.new[name]);
        props.files.new[name] = e.target.files[0];

        setPreviewRatio();
      }
    };

    const setFiles = () => {
      const isAllowedCnt = (filesCnt = 1) => {
        return props.files.new.subImgFiles.length + filesCnt <= maxSubImgFilesCnt;
      };

      if (!isAllowedCnt()) {
        store.commit("setSwingMessage", `최대 업로드 가능한 파일 개수(${maxSubImgFilesCnt}개)를 초과하였습니다.`);
        return;
      }

      store.commit("openModal", {
        name: "Uploader",
        params: {
          model: `${component.name}.files.new.subImgFiles`,
          maxCnt: maxSubImgFilesCnt,
          maxCntLabel: maxSubImgFilesCnt,
          image: true,
        },
      });
    };

    const applyFromModal = (params) => {
      props.form.new.projectInfo = params.projectInfo;
      props.keys.editor += 1;
    };

    const openAI = () => {
      store.commit("openModal", {
        name: "ChatGPT",
        params: {
          page: "apply",
          title: props.form.new.projectName,
          category: props.form.new.projectCate,
        },
        callback: `${component.name}.applyFromModal`,
      });
    };

    const delFile = (fileCode) => {
      if (props.files.new[fileCode].fileCode) {
        props.files.deletes.push(props.files.new[fileCode]);
      }

      props.files.new[fileCode] = null;
    };

    const delSubImgFile = (idx) => {
      if (props.files.new.subImgFiles[idx].fileCode) {
        props.files.deletes.push(props.files.new.subImgFiles[idx]);
      }

      props.files.new.subImgFiles.splice(idx, 1);
    };

    const getPreviewImage = (file) => {
      return file.type ? URL.createObjectURL(file) : file.filePath + file.fileSaveName;
    };

    const setPreviewRatio = () => {
      if (!props.files.new.mainImgFile) {
        return;
      }

      state.preview.loaded = false;

      const file = props.files.new.mainImgFile;
      const url = file.type ? URL.createObjectURL(file) : file.filePath + file.fileSaveName;
      let imgOrigin = new Image();

      const run = () => {
        state.preview.loaded = true;
        const ratio = imgOrigin.height / (imgOrigin.width / 4);

        if (ratio > 3.3 || ratio < 2.7) {
          state.preview.recommendRatio = false;
        } else {
          state.preview.recommendRatio = true;
        }

        imgOrigin = null;
      };

      imgOrigin.onload = run;
      imgOrigin.src = url;
    };

    return {
      component,
      state,
      editorRef,
      hashtagRef,
      maxSubImgFilesCnt,
      setVideoFlag,
      setHashtag,
      removeHashtag,
      clearInput,
      setFile,
      setFiles,
      delFile,
      delSubImgFile,
      getPreviewImage,
      applyFromModal,
      openAI,
      fetch,
      clickHashTag,
      hashtagFocusOut,
    };
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.apply";

.step1 {
  .form-group {
    &.file {
      .preview {
        > .title {
          .alert {
            color: #856404;
            background-color: #fff3cd;
            border-color: #ffeeba;
            margin-top: $px10;
          }
        }

        > .wrapper {
          position: relative;
          margin-top: $px11;
          height: $px450;
          text-align: center;
          background: #fff;
          outline: $px1 solid #eee;

          img.frame {
            position: absolute;
            top: 0;
            left: 50%;
            height: 100%;
            transform: translateX(-50%);
            outline: $px1 solid #eee;
            background: #f7f7f7;
          }

          > .inner {
            position: absolute;
            top: 0;
            left: 50%;
            height: 100%;
            width: 100%;
            transform: translateX(-50%);

            img.origin {
              height: 100%;
              object-fit: cover;
              outline: $px1 solid #eee;
              position: relative;
            }
          }

          > .letter-box {
            position: absolute;
            height: 100%;
            top: 0;
            background-color: rgba(0, 0, 0, 0.5);
            width: calc((100% - $px600) / 2);

            &.left {
              left: 0;
            }

            &.right {
              right: 0;
            }
          }

          &.skeleton {
            > img, > .area {
              @include skeleton;
            }

            > .letter-box {
              opacity: 1;
              @include skeleton;
            }
          }
        }
      }
    }

    &.images {
      position: relative;

      .action {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &.editor {
      .wrapper {
        textarea {
          min-height: 1000px;
        }
      }
    }

    &.hashtag {
      > ul {
        padding-top: $px15;

        > li {
          display: inline-block;
          background: #6c757d;
          color: #fff;
          margin-right: $px5;
          padding: 0 $px25 0 $px8;
          position: relative;
          height: $px31;
          overflow: hidden;
          border-radius: $px4;

          > a {
            white-space: nowrap;
            line-height: $px31;
            text-decoration: none;
          }

          > span {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            height: 100%;
            font-size: $px10;
            cursor: pointer;
            padding: 0 $px5;
            line-height: $px31;

            &:hover {
              opacity: 0.9;
            }
          }
        }
      }

      .suggestions {
        max-height: $px200;
        padding-left: 0;
        background-color: white;
        border-right: 1px solid $colorPoint;
        border-left: 1px solid $colorPoint;
        border-bottom: 1px solid $colorPoint;;
        overflow: auto;

        .suggestions-item {
          font-size: $px14;
          padding: $px10 $px15;
          cursor: pointer;

          &:hover {
            background-color: $colorPoint;
            color: #fff;
          }
        }
      }
    }
  }

  .alert {
    border: $px1 solid #eee;
    background: $colorBackground;
    padding: $px20;
    font-size: $px13;
    color: #3c3c3c;
    margin-bottom: $px25;

    p {
      margin-bottom: $px13;
    }
  }

  @media(max-width: 991px) {
    .form-group {
      &.reward-policy .content {
        padding-top: $px5;
        padding-bottom: 0;

        > .wrapper {
          padding-top: 0;
          padding-left: $px15;
          padding-right: $px15;

          &:last-child {
            padding-bottom: $px5;
          }
        }
      }

      &.file {
        .preview {
          > .wrapper {
            height: $px246;
          }
        }
      }
    }
  }
}
</style>