<template>
  <div class="image-slide apply">
    <div class="top" v-if="title">
      <label class="subject" :for="`${component.name}MainImgFile`">
        <span class="color-point" v-if="required">* </span>
        <span>{{ title }}</span>
        <div class="font-xs color-secondary">{{ desc }}</div>
      </label>
    </div>
    <div class="images thin-scrollbar" ref="imageListRef">
      <div class="image add" v-if="computedFileLength < limit || !limit" @click="setFiles()" title="파일 추가하기">
        <span class="ico" style="background-image: url('/assets/ico/page.apply.reward.step3.image.add.svg')"></span>
      </div>
      <div class="image" :class="f.main ? 'selected' : 'item'" v-for="(f, idx) in files" :key="idx" :data-idx="idx" v-show="f.delFlag !== 'Y'">
        <div class="img" :style="{backgroundImage: `url(${getImageUrl(f)})`}"></div>
        <span class="circle remove" @click.stop="removeFile(idx)">
          <i class="fa fa-times"></i>
        </span>
        <div class="bottom" v-if="mainExist" @click="setMain(idx)">
          <span class="circle check">
            <i class="fa fa-check"></i>
          </span>
          <span class="desc">{{ f.main ? "대표 이미지" : "대표 이미지로 설정" }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, onMounted, reactive, ref} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import store from "@/scripts/store";
import Phone from "@/components/Phone.vue";
import Sortable from "sortablejs";
import env from "@/scripts/env";

function Component(initialize) {
  this.name = "componentRewardImageSlide";
  this.initialize = initialize;
}

export default defineComponent({
  components: {Phone},
  mixins: [mixin],
  props: {
    files: Array,
    delFiles: Array,
    section: String,
    title: String,
    desc: String,
    required: Boolean,
    limit: Number,
    mainExist: Boolean,
    apis: {
      get: String,
      post: String,
      put: String,
      delete: String,
    },
  },
  setup(props, {emit}) {
    const component = new Component(() => {
    });

    const state = reactive({});

    const computedFileLength = computed(() => {
      if (props.files) {
        return props.files.filter(f => f.delFlag !== "Y").length;
      }

      return 0;
    });

    const imageListRef = ref();

    const getImageUrl = (file) => {
      return file.filePath ? file.filePath + file.fileSaveName : window.URL.createObjectURL(file);
    };

    const setFiles = () => {
      if (computedFileLength.value >= props.limit) {
        return store.commit("setSwingMessage", `최대 업로드 가능한 파일 개수(${props.limit}개)를 초과하였습니다.`);
      }

      store.commit("openModal", {
        name: "Uploader",
        params: {
          model: `${component.name}.files`,
          maxCnt: props.limit + props.files.filter(f => f.delFlag === "Y").length,
          maxCntLabel: props.limit,
          image: true,
        },
        callback: (props.mainExist && props.files.every(f => !f.main)) && `${component.name}.setMain`,
      });
    };

    const setMain = (idx) => {
      if (!props.mainExist) {
        return;
      }

      const files = [];
      const index = idx || 0;

      props.files.forEach((f, i) => {
        f.main = i === index;
        files.push(f);
      });

      emit("update:files", files);
    };

    const removeFile = (idx) => {
      if (props.files.length > 1 && props.files[idx].main) {
        idx === 0 ? props.files[1].main = true : props.files[0].main = true;
      }

      if (props.files[idx].id) {
        return props.files[idx].delFlag = "Y";
      } else if (props.files[idx].fileCode) {
        props.delFiles.push(props.files[idx]);
      }

      props.files.splice(idx, 1);
    };

    const setSortable = () => {
      const $imageListWrapper = imageListRef.value;
      const onEnd = () => {
        const $imageItems = $imageListWrapper.querySelectorAll(".item");

        for (let i = 0; i < $imageItems.length; i += 1) {
          props.files.find((f, idx) => idx.toString() === $imageItems[i].dataset.idx?.toString()).ord = i;
        }
      };

      const onMove = (e) => {
        return e.related.className.indexOf("add") === -1 && e.related.className.indexOf("selected") === -1;
      };

      const options = {
        handle: ".item",
        filter: ".add",
        animation: 350,
        forceFallback: true,
        fallbackTolerance: 5,
        onEnd,
        onMove,
      };

      new Sortable($imageListWrapper, options);
    };

    env.device !== "mobile" && onMounted(() => {
      nextTick(() => {
        setSortable();
      });
    });

    return {
      component
      , state
      , computedFileLength
      , imageListRef
      , setFiles
      , getImageUrl
      , removeFile
      , setMain
    };
  }
});
</script>

<style lang="scss" scoped>
@import "../styles/page.apply";

.image-slide {
  .top {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  .images {
    display: flex;
    align-items: center;
    gap: $px16;
    white-space: nowrap;
    overflow: auto;

    > .image {
      background-color: $colorBackground;
      border-radius: $px16;
      border: $px1 solid $colorBorder;
      cursor: pointer;
      flex-shrink: 0;
      width: $px180;
      height: $px135;
      position: relative;
      overflow: hidden;
      -webkit-touch-callout: none;
      -webkit-user-select: none;
      -khtml-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;

      > .img {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-size: auto 100%;
        background-position: center;
      }

      .circle {
        background-color: rgba(#000, 0.3);
        border-radius: 50%;
        cursor: pointer;
        color: #fff;
        font-size: $px12;
        flex-shrink: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        width: $px20;
        height: $px20;

        &.remove {
          position: absolute;
          top: $px12;
          right: $px12;

          &:hover {
            background-color: rgba(#000, 0.6);
          }
        }
      }

      .bottom {
        background-color: rgba(#000, 0.3);
        cursor: pointer;
        position: absolute;
        bottom: 0;
        left: 0;
        padding: $px12;
        width: 100%;
        display: flex;
        align-items: center;
        gap: $px16;
        font-size: $px14;

        .desc {
          color: #ccc;
        }

        &:hover {
          .check {
            background-color: rgba(#000, 0.6);
          }

          .desc {
            color: #fff;
          }
        }
      }

      &.add {
        cursor: pointer;
        color: $colorSecondary;
        font-size: $px14;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;

        .ico {
          height: $px48;
          width: $px48;
          background-position: center;
          background-repeat: no-repeat;
          background-size: auto 100%;
        }
      }

      &.selected {
        border-color: $colorPoint;

        .bottom {
          .circle {
            &.check {
              background-color: $colorPoint;
            }
          }

          .desc {
            color: #fff;
            font-weight: 500;
          }
        }
      }
    }

    &::-webkit-scrollbar {
      height: $px5;
    }
  }

  @media (max-width: 991px) {
    .images {
      > .image {
        width: $px140;
        height: $px105;

        .bottom {
          font-size: $px12;
          padding: $px8;
          gap: $px8;
        }

        &.add {
          .ico {
            width: $px36;
            height: $px36;
          }
        }
      }
    }
  }
}
</style>