<template>
  <div class="text-section editor wider apply">
    <div class="wrapper" v-if="title">
      <label class="subject" :for="component.name + title.name">
        <span class="color-point" v-if="title.required">* </span>
        <span>{{ title.text }}</span>
        <span class="color-secondary font-xs pl-1" v-if="title.maxLength">({{ titleValue.length || 0 }}/{{ title.maxLength }})</span>
      </label>
      <input :id="component.name + title.name" class="form-control border-focus-point" type="text" :maxlength="title.maxLength" :placeholder="title.placeholder" v-model="computedTitleValue">
    </div>
    <div class="wrapper">
      <label class="subject">
        <span class="color-point" v-if="content.required">* </span>
        <span>{{ content.text }}</span>
        <span class="color-secondary font-xs pl-2" v-if="content.maxLength">최대 {{ content.maxLength }}자까지 작성 가능합니다.</span>
      </label>
      <SimpleTinyEditor :id="component.name + content.name" :value.sync="computedContentValue" ref="editorRef" :key="editorKey" :height="content.height" :maxLength="content.maxLength"
                        :placeholder="content.placeholder" page="apply step3"/>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import mixin from "@/scripts/mixin";
import SimpleTinyEditor from "@/components/SimpleTinyEditor.vue";

function Component(initialize) {
  this.name = "componentRewardApplyTextSection";
  this.initialize = initialize;
}

export default defineComponent({
  components: {SimpleTinyEditor},
  mixins: [mixin],
  props: {
    editorKey: Number,
    hideTitle: Boolean,
    titleValue: {
      type: String,
      default: "",
    },
    contentValue: String,
    title: Object,
    content: Object,
  },
  setup(props, {emit}) {
    const component = new Component(() => {
    });

    const state = reactive({});

    const computedTitleValue = computed({
      get: () => props.titleValue,
      set: (val) => emit("update:titleValue", val),
    });

    const computedContentValue = computed({
      get: () => props.contentValue,
      set: (val) => emit("update:contentValue", val),
    });

    return {component, state, computedTitleValue, computedContentValue};
  }
});
</script>

<style lang="scss" scoped>
@import "../styles/page.apply";

.text-section {
  display: flex;
  flex-direction: column;
  gap: $px16;
}
</style>