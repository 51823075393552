<template>
  <div class="step4 apply">
    <div class="row">
      <template v-if="form.new.simulationFlag === 'Y'">
        <div class="form-group col-md-4">
          <label class="subject font-sm">
            <span>파트너십</span>
          </label>
          <input type="text" class="form-control border-focus-point font-sm" :value="state.partnerShipDetail.contestName" disabled/>
        </div>
        <div class="form-group col-md-4">
          <label class="subject font-sm">
            <span>펀딩 기간</span>
          </label>
          <input type="text" class="form-control border-focus-point font-sm"
                 :value="`${$lib.getDateFormat(state.partnerShipDetail.fundingStartDate, 'yyyy-MM-dd')} ~ ${$lib.getDateFormat(state.partnerShipDetail.fundingEndDate, 'yyyy-MM-dd')}`" disabled/>
        </div>
      </template>
      <template v-else>
        <div class="col-md-4">
          <div class="form-group period">
            <label class="subject font-sm" :for="`${component.name}InvestStartDate`">
              <span class="color-point">*</span>
              <span> 펀딩 시작일</span>
            </label>
            <Date componentNameSuffix="S" :id="`${component.name}InvestStartDate`" class="font-sm" placeholder="시작일" :value.sync="form.new.investStartDate"/>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group period">
            <label class="subject font-sm" :for="`${component.name}InvestEndDate`">
              <span class="color-point">*</span>
              <span> 펀딩 종료일</span>
            </label>
            <Date componentNameSuffix="E" :id="`${component.name}InvestEndDate`" class="font-sm" placeholder="종료일" :value.sync="form.new.investEndDate"/>
          </div>
        </div>
        <div class="col-md-4">
          <div class="form-group period">
            <label class="subject font-sm" :for="`${component.name}openReservationTime`">
              <span class="color-point">*</span>
              <span> 희망 오픈 시간</span>
            </label>
            <select :id="`${component.name}openReservationTime`" class="form-control border-focus-point font-sm" v-model="form.new.openReservationTime">
              <option :value="$lib.getNumberWithPadding(i - 1) + '0000'" v-for="i in 24" :key="i">{{ $lib.getNumberWithPadding(i - 1) }}:00</option>
            </select>
          </div>
        </div>
      </template>
      <div class="col-lg-4">
        <div class="form-group">
          <label class="subject font-sm" :for="`${component.name}TargetAmt`">
            <span class="color-point" v-if="form.new.simulationFlag !== 'Y'">* </span>
            <span>펀딩 목표 금액</span>
          </label>
          <Number :id="`${component.name}TargetAmt`" placeholder="(단위: 원)" :value.sync="form.new.targetAmt" :enter="save"/>
        </div>
      </div>
      <div class="col-lg-4" v-if="form.new.simulationFlag !== 'Y'">
        <div class="form-group">
          <label class="subject font-sm" :for="`${component.name}FundingType`">
            <span class="color-point">*</span>
            <span> 펀딩 방식</span>
          </label>
          <select :id="`${component.name}FundingType`" class="font-sm border-focus-point form-control" v-model="form.new.fundingType">
            <option :value="null">펀딩 방식을 선택해주세요.</option>
            <option value="K">Keep it all (무조건 리워드)</option>
            <option value="A">All or nothing (성공해야 리워드)</option>
          </select>
        </div>
      </div>
      <div class="col-lg-4">
        <div class="form-group">
          <label class="subject font-sm" :for="`${component.name}ProjectCate`">
            <span class="color-point">*</span>
            <span> 프로젝트 분야</span>
          </label>
          <select :id="`${component.name}ProjectCate`" class="font-sm border-focus-point form-control" title="프로젝트 분야" v-model="form.new.projectCate">
            <option :value="null">프로젝트 분야를 선택해주세요.</option>
            <option v-for="(c,idx) in state.categories" :key="idx" :value="c.codeId">{{ c.codeIdName }}</option>
          </select>
        </div>
      </div>
      <div class="col-lg-4" v-if="form.new.simulationFlag !== 'Y'">
        <label :for="`${component.name}CrowdYn`" class="subject font-sm">크라우드소싱(협업지원) 등록</label>
        <div class="form-group">
          <select :id="`${component.name}CrowdYn`" class="form-control border-focus-point font-sm" v-model="form.new.crowdYn" @change="onCrowdYnChange()">
            <option value="N">미등록</option>
            <option value="Y">등록</option>
          </select>
        </div>
      </div>
      <div class="col-lg-4">
        <label :for="`${component.name}HistoryProjectFlag`" class="subject font-sm">프로젝트 진행 이력</label>
        <div class="form-group">
          <select :id="`${component.name}HistoryProjectFlag`" class="form-control border-focus-point font-sm" v-model="form.new.historyProjectFlag">
            <option value="N">없음</option>
            <option value="Y">있음</option>
          </select>
        </div>
      </div>
      <div class="col-lg-4" v-if="form.new.historyProjectFlag === 'Y'">
        <label class="subject font-sm" :for="`${component.name}HistoryUrl`">
          <span class="color-point">*</span>
          <span> 과거 진행 프로젝트 URL</span>
        </label>
        <div class="form-group">
          <input type="text" class="form-control border-focus-point font-sm" :id="`${component.name}HistoryUrl`" placeholder="https://ohmycompany.com/" v-model="form.new.historyUrl" @keyup.enter="save()"/>
        </div>
      </div>
      <div class="col-lg-4" v-if="form.new.simulationFlag !== 'Y'">
        <label :for="`${component.name}PartnershipFlag`" class="subject font-sm">파트너십 관련 여부</label>
        <div class="form-group">
          <select :id="`${component.name}PartnershipFlag`" class="form-control border-focus-point font-sm" v-model="form.new.partnershipFlag" :disabled="form.new.simulationFlag === 'Y'">
            <option value="N">없음</option>
            <option value="Y">있음</option>
          </select>
        </div>
      </div>
      <template v-if="form.new.partnershipFlag === 'Y'">
        <div class="col-lg-4" v-if="form.new.simulationFlag !== 'Y'">
          <label :for="`${component.name}ContestSeq`" class="subject font-sm">
            <span class="color-point">*</span>
            <span> 연관 파트너십 대회</span>
          </label>
          <div class="form-group">
            <select :id="`${component.name}ContestSeq`" class="form-control border-focus-point font-sm" v-model="form.new.contestSeq">
              <option :value="null">선택</option>
              <option :value="c.contestSeq" v-for="(c, idx) in computedContests" :key="idx">
                {{ c.contestName }}
                (펀딩 기간: {{ $lib.getDateFormat(c.fundingStartDate, "yyyy-MM-dd") }}~{{ $lib.getDateFormat(c.fundingEndDate, "yyyy-MM-dd") }})
              </option>
            </select>
          </div>
        </div>
        <div class="form-group col-md-4">
          <label :for="`${component.name}GroupCate`" class="subject font-sm">
            <span class="color-point">* </span>
            <span>조직 형태</span>
          </label>
          <select :id="`${component.name}GroupCate`" class="form-control border-focus-point font-sm" v-model="form.new.groupCate">
            <option :value="null">선택</option>
            <option :value="c.codeId" v-for="(c, idx) in state.codes.orgTypes" :key="idx">{{ c.codeIdName }}</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label :for="`${component.name}CorpSector`" class="subject font-sm">
            <span class="color-point">* </span>
            <span>인증 여부</span>
          </label>
          <select :id="`${component.name}CorpSector`" class="form-control border-focus-point font-sm" v-model="form.new.corpSector">
            <option :value="null">선택</option>
            <option :value="c.codeId" v-for="(c, idx) in state.codes.orgAuthTypes" :key="idx">{{ c.codeIdName }}</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label :for="`${component.name}CorpEstablished`" class="subject font-sm">
            <span>법인설립일</span>
          </label>
          <Number :id="`${component.name}CorpEstablished`" :value.sync="form.new.corpEstablished" :allowZero="true" :noComma="true" :maxlength="8" placeholder="ex) 20220720"/>
        </div>
        <div class="form-group col-md-4">
          <label :for="`${component.name}CorpRegion`" class="subject font-sm">
            <span>지역</span>
          </label>
          <select :id="`${component.name}CorpRegion`" class="form-control border-focus-point font-sm" v-model="form.new.corpRegion">
            <option :value="null">선택</option>
            <option :value="c.codeId" v-for="(c, idx) in state.codes.corpRegions" :key="idx">{{ c.codeIdName }}</option>
          </select>
        </div>
        <div class="form-group col-md-4">
          <label :for="`${component.name}UserSex`" class="subject font-sm">
            <span>진행자 성별</span>
          </label>
          <select :id="`${component.name}UserSex`" class="form-control border-focus-point font-sm" v-model="form.new.userSex">
            <option :value="null">선택</option>
            <option value="M">남성</option>
            <option value="F">여성</option>
          </select>
        </div>
        <div class="col-12">
          <div class="form-group">
            <div class="font-sm clearfix">
              <label class="subject float-left mb-0" :for="`${component.name}CertFile`">인증 관련 서류</label>
              <div class="right-act">
                <label class="mb-0" @click.stop="clearInput($event)">
                  <a class="pointer color-point">파일 올리기</a>
                  <input :id="`${component.name}CertFile`" type="file" :accept="$definitions.limits.fileExtensions.allStr" class="hide" @change="setFile($event, 'certFile')"/>
                </label>
              </div>
            </div>
            <ul class="files tight" v-if="files.new.certFile && files.new.certFile.fileSeq ? files.new.certFile.delFlag !== 'Y' : files.new.certFile">
              <li class="font-sm pointer" :type="files.new.certFile.type" @click="$store.dispatch('previewFile', files.new.certFile)" title="클릭 시 미리보기">
                <div>{{ files.new.certFile.fileOrigName || files.new.certFile.name }}</div>
                <span class="pointer times" title="삭제" @click.stop="delFile('certFile')">&times;</span>
              </li>
            </ul>
          </div>
        </div>
        <div class="col-12">
          <div class="form-group privacy">
            <div class="header font-sm clearfix">
              <label class="subject float-left">개인(기업)정보 수집ㆍ제공 활용 동의</label>
              <div class="right-act">
                <div class="form-check">
                  <input class="form-check-input" type="checkbox" :id="`${component.name}Agree`" v-model="state.agree"/>
                  <label class="form-check-label" :for="`${component.name}Agree`">동의합니다.</label>
                </div>
              </div>
            </div>
            <div class="info font-sm">
              정보의 수집 및 이용 목적<br/><br/>
              1.전화, 이메일, 문자 등을 통한 안내<br/>
              2.각종 안내 및 사업 보고<br/>
              3.기타 파트너십 펀딩 대회 운영과 관련 업무에 활용<br/><br/>
              * 수집 항목 - 기업명, 조직형태, 인증여부, 지역, 소재지, 설립일, 참여유형, 프로젝트 유형, 대표자 성명, 대표자 성별, 연락처, 이메일, 홈페이지, 사업자등록증, 통장 사본<br/>
              * 보유 및 이용기간 해당 파트너십 펀딩대회 사업운영 마감 및 보고까지 <br/>
              * 개인정보 제공 등의 거부 권리 및 동의 거부에 따른 불이익 내용 또는 제한사항 개인정보의 필수 수집 및 이용에 동의하지 않을 경우 오마이컴퍼니 파트너십 펀딩 대회 사업 진행 시 안내가 정상적으로 제공되지 않을 수 있으면 관련하여 진행되는 사업 과정에 불편이 발생할 수 있습니다.<br/><br/>
              「개인정보 보호법」제 15조 규정에 따라 상기인의 성명 및 주소, 전화번호를 사업 추진의 목적으로 제3자에게 제공하는 것을 동의합니다.<br/>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div :id="`${component.name}Sourcing`" class="sourcing" v-if="form.new.crowdYn === 'Y'">
      <div class="wrapper">
        <label>크라우드소싱(협업지원)</label>
        <a class="font-sm pointer-u" @click="appendCrowdListItem()">+ 항목 추가</a>
        <div class="items">
          <div class="item" v-for="(c, idx) in form.new.crowdList" :key="idx" v-show="c.delFlag !== 'Y'">
            <div class="form-group">
              <label :for="`${component.name}CrowdSourcingType${idx}`" class="subject font-sm">
                <span class="color-point">* </span>
                <span>자원 성격</span>
              </label>
              <select :id="`${component.name}CrowdSourcingType${idx}`" class="form-control font-sm border-focus-point" v-model="c.crowdsourcingType">
                <option value="">지원 성격을 선택해주세요.</option>
                <option value="people">구인</option>
                <option value="talent">재능기부</option>
                <option value="product">물품기부</option>
                <option value="partner">파트너</option>
                <option value="etc">기타</option>
              </select>
            </div>
            <div class="form-group">
              <label :for="`${component.name}CrowdSourcingTitle${idx}`" class="subject font-sm">
                <span class="color-point">* </span>
                <span>제목</span>
              </label>
              <input :id="`${component.name}CrowdSourcingTitle${idx}`" type="text" class="form-control border-focus-point font-sm" placeholder="ex) 본 행사의 봉사자를 신청 받습니다." v-model="c.title">
            </div>
            <div class="form-group">
              <label :for="`${component.name}CrowdSourcingContent${idx}`" class="subject font-sm">
                <span class="color-point">* </span>
                <span>내용</span>
              </label>
              <textarea :id="`${component.name}CrowdSourcingContent${idx}`" type="text" class="form-control border-focus-point font-sm"
                        :placeholder="'ex) 모집 인원: 3명\n활동 일시: 2023년 2월 1일 09시\n활동 지역: 서울 시청 광장\n활동 혜택: 날아라! 슈퍼보드 기념 티셔츠 제공, 봉사 활동 시간 인정(총 16시간)'" v-model="c.content"></textarea>
            </div>
            <i class="fa fa-trash" title="삭제" @click="c.delFlag = 'Y'" v-if="idx !== 0"></i>
          </div>
        </div>
      </div>
    </div>
    <div class="alert">
      <ul class="tight">
        <li>
          <p>
            <span>펀딩기간과 오픈 시간은 프로젝트 심사/승인/협의 과정에서 조정될 수 있습니다.</span>
          </p>
        </li>
        <li>
          <p>
            <span>keep it all(무조건 리워드)방식은 목표액을 달성하지 못해도 후원하신 분께 리워드를 제공하는 방식입니다.</span>
            <br/>
            <span>all or nothing(성공해야 리워드)방식은 목표액을 달성하면 후원하신 분께 리워드를 제공하는 방식입니다. </span>
          </p>
        </li>
        <li>
          <div>
            <span>무조건 리워드 방식의 운영 수수료는 7%, 성공해야 리워드 방식의 운영 수수료는 5%입니다.</span>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, nextTick, reactive, ref} from "@vue/composition-api";
import http from "@/scripts/http";
import lib from "@/scripts/lib";
import TinyEditor from "@/components/TinyEditor";
import store from "@/scripts/store";
import mixin from "@/scripts/mixin";
import Date from "@/components/Date";
import Number from "@/components/Number";
import {httpError} from "@/scripts/httpError";

function Component(initialize) {
  this.name = "pageApplyRewardMakerstep4";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    form: Object,
    files: Object,
    validators: Object,
    save: Function,
    warn: Function,
  },
  components: {Number, Date, TinyEditor},
  setup(props) {
    const component = new Component(() => {
      const promises = [
        http.get("/api/codes?parentCodeId=ORG_TYPE").catch(httpError()),
        http.get("/api/codes?parentCodeId=ORG_AUTH_TYPE").catch(httpError()),
        http.get("/api/codes?parentCodeId=CORP_REGION").catch(httpError()),
      ];

      Promise.all(promises).then(responses => {
        state.codes.orgTypes = responses[0].data.body;
        state.codes.orgAuthTypes = responses[1].data.body;
        state.codes.corpRegions = responses[2].data.body;
      });

      if (props.form.new.partnershipFlag === "Y") {
        state.agree = props.form.new.step4 === "1";
      }

      if (props.form.new.simulationFlag === "Y") {
        http.get(`/api/partnerships/contests/${props.form.new.contestSeq}`).then(({data}) => {
          state.partnerShipDetail = data.body;
        });
      }

      if (props.form.new.tag) {
        for (let t of lib.getSplit(props.form.new.tag, ["\r\n", "\r", "\n", ","])) {
          t && t.trim() && state.tags.push(t.replaceAll("#", "").trim());
        }
      }

      state.video = props.form.new.videoFlag === "Y";

      props.validators[4] = () => {
        if (props.form.new.simulationFlag !== "Y") {
          if (!props.form.new.investStartDate) {
            return props.warn("펀딩 시작일을 입력해주세요.", `${component.name}InvestStartDate`);
          } else if (props.form.new.investStartDate < lib.getDateFormat(new window.Date(), "yyyyMMdd")) {
            return props.warn("펀딩 시작일을 오늘 혹은 이후의 날짜로 입력해주세요.", `${component.name}InvestStartDate`);
          } else if (!props.form.new.investEndDate) {
            return props.warn("펀딩 종료일을 입력해주세요.", `${component.name}InvestEndDate`);
          } else if (props.form.new.investEndDate <= lib.getDateFormat(new window.Date(), "yyyyMMdd")) {
            return props.warn("펀딩 종료일을 오늘 이후의 날짜로 입력해주세요.", `${component.name}InvestEndDate`);
          } else if (props.form.new.investStartDate >= props.form.new.investEndDate) {
            return props.warn("펀딩 종료일을 시작일 이후의 날짜로 입력해주세요.", `${component.name}InvestEndDate`);
          } else if (!props.form.new.openReservationTime) {
            return props.warn("펀딩 오픈 시간을 입력해주세요.", `${component.name}openReservationTime`);
          } else if (!props.form.new.targetAmt) {
            return props.warn("펀딩 목표 금액을 입력해주세요.", `${component.name}TargetAmt`);
          } else if (!props.form.new.fundingType) {
            return props.warn("펀딩 방식을 선택해주세요.", `${component.name}FundingType`);
          }
        }

        if (!props.form.new.projectCate) {
          return props.warn("프로젝트 분야를 선택해주세요.", `${component.name}ProjectCate`);
        }

        props.form.new.historyUrl = props.form.new.historyUrl?.trim();

        if (props.form.new.crowdYn === "Y") {
          for (let i in props.form.new.crowdList) {
            if (props.form.new.crowdList[i].delFlag === "Y") {
              continue;
            }

            if (!props.form.new.crowdList[i].crowdsourcingType) {
              return props.warn("자원 성격을 선택해주세요.", `${component.name}CrowdSourcingType${i}`);
            } else if (!props.form.new.crowdList[i].title?.trim()) {
              return props.warn("제목을 입력해주세요.", `${component.name}CrowdSourcingTitle${i}`);
            } else if (!props.form.new.crowdList[i].content?.trim()) {
              return props.warn("내용을 입력해주세요.", `${component.name}CrowdSourcingContent${i}`);
            }
          }
        }

        if (props.form.new.historyProjectFlag === "Y") {
          if (!props.form.new.historyUrl) {
            return props.warn("프로젝트 주소(URL)를 입력해주세요.", `${component.name}HistoryUrl`);
          } else if (!lib.isValidUrl(props.form.new.historyUrl)) {
            return props.warn("프로젝트 주소(URL) 값이 유효하지 않습니다.", `${component.name}HistoryUrl`);
          }
        }

        if (props.form.new.partnershipFlag === "Y") {
          if (!props.form.new.contestSeq) {
            return props.warn("연관 파트너십 대회를 선택해주세요.", `${component.name}ContestSeq`);
          } else if (!props.form.new.groupCate) {
            return props.warn("조직 형태를 선택해주세요.", `${component.name}GroupCate`);
          } else if (!props.form.new.corpSector) {
            return props.warn("인증 여부를 선택해주세요.", `${component.name}CorpSector`);
          } else if (props.form.new.corpEstablished && props.form.new.corpEstablished.length !== 8) {
            return props.warn("법인 설립일을 올바르게 입력해주세요.", `${component.name}CorpEstablished`);
          } else if (!state.agree) {
            return props.warn("개인(기업)정보 수집 및 제공 활용에 동의하셔야 진행 가능합니다.", `${component.name}Agree`);
          }

          props.form.new.corpEstablishedFlag = props.form.new.corpEstablished ? "Y" : "N";
        }

        return true;
      };

      http.get("/api/reward/categories").then(({data}) => {
        state.categories = data.body;
      });

      http.get("/api/partnerships/contests").then(({data}) => {
        state.contests = data.body;
      });
    });

    const state = reactive({
      video: false,
      contests: [],
      categories: [],
      tags: [],
      partnerShipDetail: {
        contestName: "",
        fundingStartDate: "",
        fundingEndDate: "",
      },
      codes: {
        orgTypes: [],
        orgAuthTypes: [],
        corpRegions: [],
      },
    });

    const editorRef = ref();

    const computedContests = computed(() => state.contests.filter(c => c.contestType !== "S"));

    const setVideoFlag = () => {
      props.form.new.videoFlag = state.video ? "Y" : "N";
    };

    const syncHashtag = () => {
      props.form.new.tag = state.tags.map(t => "#" + t + "\r\n").join("");
    };

    const setHashtag = (e) => {
      if ([13, 188].includes(e.keyCode)) {
        const newHashtag = e.target.value?.replaceAll(",", "")?.replaceAll("#", "")?.trim();

        if (!newHashtag) {
          return;
        } else if (state.tags.includes(newHashtag)) {
          return store.commit("setSwingMessage", "입력하신 해시태그가 이미 존재합니다.");
        }

        if (state.tags.length >= 5) {
          store.commit("setSwingMessage", "해시태그는 5개까지 입력 가능합니다.");
        } else {
          state.tags.push(newHashtag);
          syncHashtag();
        }

        e.target.value = "";
      }
    };

    const removeHashtag = (idx) => {
      state.tags.splice(idx, 1);
      syncHashtag();
    };

    const clearInput = (e) => {
      e.currentTarget.querySelector("input").value = "";
    };

    const setFile = (e, name) => {
      if (!store.getters.isAllowedExtension(e.target, "all")) {
        return;
      }

      if (e.target.files.length && props.files.new[name] !== undefined) {
        props.files.new[name]?.fileSeq && props.files.deletes.push(props.files.new[name]);
        props.files.new[name] = e.target.files[0];
      }
    };

    const delFile = (fileCode) => {
      if (props.files.new[fileCode].fileCode) {
        props.files.deletes.push(props.files.new[fileCode]);
      }

      props.files.new[fileCode] = null;
    };

    const appendCrowdListItem = () => {
      props.form.new.crowdList.push(lib.getRenewed(props.form.default.crowdListItem));
    };

    const onCrowdYnChange = () => {
      if (props.form.new.crowdYn === "Y") {
        if (!props.form.new.crowdList) {
          props.form.new.crowdList = [];
        }

        !props.form.new.crowdList.length && props.form.new.crowdList.push(lib.getRenewed(props.form.default.crowdListItem));

        nextTick(() => {
          const $sourcing = document.getElementById(`${component.name}Sourcing`);
          const scrollTop = window.pageYOffset || document.documentElement.scrollTop;

          window.scrollTo({
            top: $sourcing.getBoundingClientRect().top + scrollTop - 60 - lib.getHeaderHeight(),
            left: 0,
            behavior: "smooth"
          });
        });
      }
    };

    return {component, state, editorRef, computedContests, setVideoFlag, setHashtag, removeHashtag, clearInput, setFile, delFile, appendCrowdListItem, onCrowdYnChange};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.apply";

.step4 {
  .form-group {
    &.images {
      position: relative;

      .action {
        position: absolute;
        top: 0;
        right: 0;
      }
    }

    &.editor {
      .wrapper {
        textarea {
          min-height: $px420;
        }
      }
    }

    &.hashtag {
      > ul {
        padding-top: $px15;

        > li {
          display: inline-block;
          background: #6c757d;
          color: #fff;
          margin-right: $px5;
          padding: 0 $px25 0 $px8;
          position: relative;
          height: $px31;
          overflow: hidden;
          border-radius: $px4;

          > a {
            white-space: nowrap;
            line-height: $px31;
            text-decoration: none;
          }

          > span {
            position: absolute;
            top: 50%;
            right: 0;
            transform: translateY(-50%);
            height: 100%;
            font-size: $px10;
            cursor: pointer;
            padding: 0 $px5;
            line-height: $px31;

            &:hover {
              opacity: 0.9;
            }
          }
        }
      }
    }

    &.reward-policy {
      .content {
        background: $colorBackground;
        border: $px1 solid #eee;
        border-radius: $px4;
        padding: $px15 0;

        > .wrapper {
          padding: $px15 $px30;

          .row {
            padding-bottom: $px15;

            .subtitle {
              span {
                display: inline-block;
                padding: $px10 0;
              }
            }

            textarea {
              min-height: $px120;
            }
          }
        }
      }
    }

    &.privacy {
      .header {
        padding-right: $px90;
      }

      .info {
        height: $px140;
        overflow: auto;
        border: $px1 solid #eee;
        background: $colorBackground;
        padding: $px15;
      }
    }
  }

  .sourcing {
    padding: $px25;
    border: $px1 solid #eee;
    margin-bottom: $px25;

    > .wrapper {
      position: relative;

      > label {
        margin-bottom: $px25;
      }

      > a {
        position: absolute;
        top: 0;
        right: 0;
      }

      .items {
        > .item {
          padding: $px25;
          background: $colorBackground;
          position: relative;
          border: $px1 solid #eee;

          .form-group {
            > label {
              margin-bottom: $px10;
            }

            textarea {
              min-height: $px116;
            }

            &:last-child {
              margin-bottom: 0;
            }
          }

          > i {
            cursor: pointer;
            position: absolute;
            top: $px15;
            right: $px15;
          }

          &:not(:first-child) {
            padding-top: $px25;
            margin-top: $px35;
            border-top: $px1 solid #eee
          }
        }
      }
    }
  }

  .alert {
    border: $px1 solid #eee;
    background: $colorBackground;
    padding: $px20;
    font-size: $px13;
    color: #3c3c3c;
    margin-bottom: $px25;

    > ul > li {
      position: relative;
      padding-left: $px10;

      &:before {
        content: "-";
        position: absolute;
        top: 0;
        left: 0;
        line-height: 1.25;
      }
    }

    p {
      margin-bottom: $px13;
    }
  }

  @media(max-width: 991px) {
    .form-group.reward-policy .content {
      padding-top: $px5;
      padding-bottom: 0;

      > .wrapper {
        padding-top: 0;
        padding-left: $px15;
        padding-right: $px15;

        &:last-child {
          padding-bottom: $px5;
        }
      }
    }
  }
}
</style>