<template>
  <div class="step2 apply">
    <div class="form-group">
      <div class="guide">
        <div class="title">
          <span>배송비</span>
        </div>
      </div>
      <div class="content">
        <div class="wrapper">
          <label class="subject" :for="`${component.name}DeliveryAmt`">
            <span class="color-point">*</span>
            <span> 리워드 배송비</span>
          </label>
          <Number :id="`${component.name}DeliveryAmt`" placeholder="(단위: 원)" :allowZero="true" :value.sync="form.new.deliveryAmt" :enter="save"/>
        </div>
      </div>
    </div>
    <div class="form-group">
      <div class="guide">
        <div class="title">
          <span>리워드 구성</span>
        </div>
      </div>
      <div class="content">
        <ul class="rewards tight">
          <li v-for="(item1, idx1) in computedRewardItems" :key="idx1">
            <label class="subject">리워드 {{ idx1 + 1 }}</label>
            <div class="actions">
              <a class="font-sm pointer-u" @click="remove(idx1)" v-if="computedRewardItems.length > 1">- &nbsp;리워드 삭제</a>
              <a class="font-sm pointer-u" @click="insert(idx1)">+ &nbsp;리워드 추가</a>
            </div>
            <div class="wrapper">
              <div class="row">
                <div class="input col-md-3">
                  <label class="subject font-sm" :for="`${component.name}RewardAmt${idx1}`">
                    <span class="color-point">*</span>
                    <span> 금액</span>
                  </label>
                  <Number :id="`${component.name}RewardAmt${idx1}`" placeholder="(단위: 원)" :value.sync="item1.rewardAmt" :enter="save"/>
                </div>
                <div class="input col-md-2">
                  <label class="subject font-sm" :for="`${component.name}DisplayOrder${idx1}`">노출 순서</label>
                  <select :id="`${component.name}DisplayOrder${idx1}`" class="form-control border-focus-point font-sm" v-model="item1.displayOrder">
                    <option :value="n.toString()" v-for="(n, idx2) in computedRewardItems.length" :key="idx2">{{ n }}</option>
                  </select>
                </div>
                <div class="input col-md-7">
                  <label class="subject font-sm" :for="`${component.name}RewardTitle${idx1}`">
                    <span class="color-point">*</span>
                    <span> 리워드 상세</span>
                  </label>
                  <input :id="`${component.name}RewardTitle${idx1}`" type="text" class="form-control border-focus-point font-sm" placeholder="ex) 날아라 슈퍼보드 기념 티셔츠1개 + 감사의 손편지" v-model="item1.title" @keyup.enter="save()"/>
                </div>
              </div>
              <div class="row options">
                <div class="input col-md-3">
                  <label class="subject font-sm" :for="`${component.name}RewardQtyLimit${idx1}`">수량 구분</label>
                  <select :id="`${component.name}RewardQtyLimit${idx1}`" class="form-control border-focus-point font-sm" v-model="item1.rewardQtyLimit" @change="onRewardQtyLimitChange(idx1)">
                    <option :value="false">제한 없음</option>
                    <option :value="true">수량 한정</option>
                  </select>
                </div>
                <div class="input col-md-2">
                  <label class="subject font-sm" :for="`${component.name}RewardQty${idx1}`">개수</label>
                  <Number :id="`${component.name}RewardQty${idx1}`" placeholder="ex) 10" :value.sync="item1.rewardQty" :enter="save"
                          :disabled="!item1.rewardQtyLimit"/>
                </div>
                <div class="col-md-7">
                  <div class="block reward">
                    <label class="subject font-sm" :for="`${component.name}RewardSelFlag${idx1}`">
                      <div>
                        <span>리워드 선택 옵션</span>
                        <i class="help v-middle ml-1 fa fa-question-circle-o" title="도움말" data-text="리워드 중에서 별도로 후원자들의 기호에 따른 선택이 필요한 경우 안내 글을 삽입하는 기능입니다.<br /><br />Ex) 컬러, 사이즈 등 빨강, 노랑, 초록 중 1가지 컬러를 선택기입해주세요. S, X, L 사이즈를 선택해주세요."></i>
                      </div>
                      <a class="font-sm pointer-u side" v-if="item1.rewardSelFlag === 'Y'" @click="pushRewardOptionItems(idx1)">+ &nbsp;선택 옵션 추가</a>
                    </label>
                    <div class="row">
                      <div class="input col-md-4">
                        <select :id="`${component.name}RewardSelFlag${idx1}`" v-model="item1.rewardSelFlag" class="form-control border-focus-point font-sm" @change="setRewardQtyFromItemsSum()">
                          <option value="N">사용 안함</option>
                          <option value="Y">사용함</option>
                        </select>
                      </div>
                      <div class="input col-md-8">
                        <input type="text" class="form-control border-focus-point font-sm" placeholder="ex) 리워드 컬러 입력 필요" v-model="item1.rewardSelText" @keyup.enter="save()" :disabled="item1.rewardSelFlag === 'N'"/>
                      </div>
                    </div>
                  </div>
                </div>
                <template v-if="item1.rewardSelFlag === 'Y'">
                  <div class="reward-option-items col-12" v-if="item1.rewardOptionItems.filter(i => i.delFlag !== 'Y').length">
                    <div class="wrapper" v-for="(item2, idx2) in item1.rewardOptionItems.filter(i => i.delFlag !== 'Y')" :key="idx2">
                      <div class="row">
                        <div class="col-5">
                          <label class="subject font-sm" :for="`${component.name}rewardOptionText${idx1}${idx2}`">선택 옵션</label>
                          <input :id="`${component.name}rewardOptionText${idx1}${idx2}`" type="text" class="form-control font-sm border-focus-point" placeholder="ex) Green" v-model="item2.rewardOptionText" @keyup.enter="save()"/>
                        </div>
                        <div class="col-3 pl-md-0">
                          <label class="subject font-sm" :for="`${component.name}rewardOptionQty${idx1}${idx2}`">
                            <span>개수</span>
                          </label>
                          <Number :id="`${component.name}rewardOptionQty${idx1}${idx2}`" placeholder="(단위: 개)" :value.sync="item2.rewardOptionQty" :enter="save" :disabled="!item1.rewardQtyLimit" :change="setRewardQtyFromItemsSum"/>
                        </div>
                        <div class="col-3 pl-md-0">
                          <label class="subject font-sm" :for="`${component.name}DisplayOrder${idx1}${idx2}`">순서</label>
                          <select :id="`${component.name}DisplayOrder${idx1}${idx2}`" class="form-control font-sm border-focus-point" v-model="item2.displayOrder">
                            <option :value="n.toString()" v-for="(n, idx3) in item1.rewardOptionItems.filter(i => i.delFlag !== 'Y') .length" :key="idx3">{{ n }}</option>
                          </select>
                        </div>
                        <div class="remove col-1">
                          <label class="subject font-sm">&nbsp;</label>
                          <button class="btn" @click="removeRewardOptionItem(idx1, idx2)">
                            <i class="fa fa-trash"></i>
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </template>
              </div>
              <div class="info font-sm" v-if="item1.rewardQtyLimit">※ 수량 한정의 경우, '가상계좌' 결제 수단이 제한됩니다.</div>
              <div class="wrapper mb-0 block">
                <div class="top">
                  <label class="subject font-sm" :for="`${component.name}RewardExpectText${idx1}`">
                    <span class="color-point">*</span>
                    <span> 리워드 예상 제공일</span>
                  </label>
                  <div class="check side font-sm form-check" v-if="idx1 === 0">
                    <input class="form-check-input" type="checkbox" :id="`${component.name}RewardExpectTextApplyAll`" v-model="state.applyAll" @change="onApplyAllChange()"/>
                    <label class="form-check-label" :for="`${component.name}RewardExpectTextApplyAll`">일괄 적용</label>
                  </div>
                </div>
                <input :id="`${component.name}RewardExpectText${idx1}`" type="text" class="form-control border-focus-point font-sm" placeholder="ex) 2022년 1월 1일부터 리워드 발송" v-model="item1.rewardExpectText" @input="onRewardExpectTextChange()"
                       @keyup.enter="save()"/>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
</template>

<script>
import {computed, defineComponent, reactive} from "@vue/composition-api";
import lib from "@/scripts/lib";
import Number from "@/components/Number";
import mixin from "@/scripts/mixin";
import Vue from "vue";

function Component(initialize) {
  this.name = "pageApplyRewardMakerstep2";
  this.initialize = initialize;
}

export default defineComponent({
  mixins: [mixin],
  props: {
    form: Object,
    files: Object,
    validators: Object,
    save: Function,
    warn: Function,
  },
  components: {Number},
  setup(props) {
    const component = new Component(() => {
      props.validators[2] = () => {
        if (["", undefined, null].includes(props.form.new.deliveryAmt)) {
          return props.warn("리워드 배송비를 입력해주세요.", `${component.name}DeliveryAmt`);
        }

        const displayOrder1Arr = [];

        for (let i in computedRewardItems.value) {
          const rewardOptionItems = computedRewardItems.value[i].rewardOptionItems.filter(i => i.delFlag !== "Y");

          computedRewardItems.value[i].title = computedRewardItems.value[i].title?.trim();
          computedRewardItems.value[i].rewardExpectText = computedRewardItems.value[i].rewardExpectText?.trim();

          if (!computedRewardItems.value[i].rewardAmt) {
            return props.warn("금액을 입력해주세요.", `${component.name}RewardAmt${i}`);
          } else if (!computedRewardItems.value[i].title) {
            return props.warn("리워드 구성을 입력해주세요.", `${component.name}RewardTitle${i}`);
          } else if (computedRewardItems.value[i].rewardQtyLimit && !computedRewardItems.value[i].rewardQty
              && (computedRewardItems.value[i].rewardSelFlag !== "Y" || !rewardOptionItems.length)) {
            return props.warn("리워드 개수를 입력해주세요.", `${component.name}RewardQty${i}`);
          }

          if (computedRewardItems.value[i].rewardSelFlag === "N") {
            computedRewardItems.value[i].rewardOptionItems = [];
          }

          if (computedRewardItems.value[i].rewardSelFlag === "Y" && rewardOptionItems.length) {
            const displayOrder2Arr = [];

            for (let j in rewardOptionItems) {
              if (!rewardOptionItems[j].rewardOptionText) {
                return props.warn("선택 옵션을 입력해주세요.", `${component.name}rewardOptionText${i}${j}`);
              } /*else if (computedRewardItems.value[i].rewardQtyLimit && rewardOptionItems[j].rewardOptionQty <= 0) {
                return props.warn("개수를 입력해주세요.", `${component.name}rewardOptionQty${i}${j}`);
              }*/ else if (displayOrder2Arr.includes(rewardOptionItems[j].displayOrder)) {
                return props.warn("중복된 순서 값이 있습니다.", `${component.name}DisplayOrder${i}${j}`);
              }

              displayOrder2Arr.push(rewardOptionItems[j].displayOrder);
            }
          }

          if (!computedRewardItems.value[i].rewardExpectText) {
            return props.warn("리워드 예상 제공일을 입력해주세요.", `${component.name}RewardExpectText${i}`);
          } else if (displayOrder1Arr.includes(computedRewardItems.value[i].displayOrder)) {
            return props.warn("중복된 노출 순서가 있습니다.", `${component.name}DisplayOrder${i}`);
          }

          displayOrder1Arr.push(computedRewardItems.value[i].displayOrder);
        }

        setRewardQtyFromItemsSum();
        return true;
      };
    });

    const state = reactive({
      applyAll: false
    });

    const computedRewardItems = computed(() => {
      return props.form.new.rewardItems.filter(i => i.delFlag !== "Y");
    });

    const insert = (idx) => {
      const rewardItem = lib.getRenewed(props.form.default.rewardItem);
      rewardItem.rewardSeq = null;
      rewardItem.rewardQtyLimit = false;

      if (state.applyAll) {
        rewardItem.rewardExpectText = computedRewardItems.value[0].rewardExpectText;
      }

      idx = props.form.new.rewardItems.findIndex(i => i === computedRewardItems.value[idx]);
      props.form.new.rewardItems.splice(idx + 1, 0, rewardItem);
      sort();

      setTimeout(() => {
        rewardItem.rewardSeq = "";
      });
    };

    const pushRewardOptionItems = (idx1) => {
      const rewardOptionItem = lib.getRenewed(props.form.default.rewardOptionItem);
      rewardOptionItem.displayOrder = (computedRewardItems.value[idx1].rewardOptionItems.filter(i => i.delFlag !== "Y").length + 1).toString();
      computedRewardItems.value[idx1].rewardOptionItems.push(rewardOptionItem);
      setRewardQtyFromItemsSum();
    };

    const setRewardQtyFromItemsSum = () => {
      for (let i in computedRewardItems.value) {
        const rewardOptionItems = computedRewardItems.value[i].rewardOptionItems.filter(i => i.delFlag !== "Y");

        if (computedRewardItems.value[i].rewardSelFlag === "Y" && rewardOptionItems.length && !computedRewardItems.value[i].rewardQtyLimit) {
          const rewardOptionItemsQtySum = rewardOptionItems.map(i => window.Number(i.rewardOptionQty || 0)).reduce((sum, x) => sum + x);
          computedRewardItems.value[i].rewardQty = rewardOptionItemsQtySum;
        }
      }
    };

    const remove = (idx) => {
      if (computedRewardItems.value[idx].rewardSeq) {
        computedRewardItems.value[idx].delFlag = "Y";
      } else {
        const originArr = lib.getRenewed(computedRewardItems.value);
        originArr.splice(idx, 1);
        Vue.set(props.form.new, "rewardItems", [...props.form.new.rewardItems.filter(r => r.delFlag === "Y"), ...originArr]);
      }
      sort();
    };

    const removeRewardOptionItem = (idx1, idx2) => {
      const rewardOptionItems = computedRewardItems.value[idx1].rewardOptionItems.filter(i => i.delFlag !== "Y");
      rewardOptionItems[idx2].delFlag = "Y";

      for (let i in rewardOptionItems) {
        const rewardOptionItem = rewardOptionItems[i];
        const rewardOptionItemsFilteredLen = rewardOptionItems.filter(i => i.delFlag !== "Y").length;

        if (rewardOptionItem.delFlag !== "Y" && rewardOptionItemsFilteredLen < rewardOptionItem.displayOrder) {
          rewardOptionItem.displayOrder = rewardOptionItemsFilteredLen;
        }
      }

      setRewardQtyFromItemsSum();
    };

    const sort = () => {
      for (let i = 0; i < computedRewardItems.value.length; i += 1) {
        computedRewardItems.value[i].displayOrder = (i + 1).toString();
      }
    };

    const onRewardQtyLimitChange = (idx) => {
      if (!computedRewardItems.value[idx].rewardQtyLimit) {
        computedRewardItems.value[idx].rewardQty = null;
        computedRewardItems.value.forEach((item) => {
          item.rewardOptionItems.filter(i => i.delFlag !== "Y").forEach(i => i.rewardOptionQty = 0);
        });
      } else {
        setRewardQtyFromItemsSum();
      }
    };

    const onRewardExpectTextChange = () => {
      for (let i in computedRewardItems.value) {
        if (computedRewardItems.value[i].rewardExpectText !== computedRewardItems.value[0].rewardExpectText) {
          state.applyAll = false;
          break;
        }
      }
    };

    const onApplyAllChange = () => {
      if (state.applyAll) {
        for (let i in computedRewardItems.value) {
          computedRewardItems.value[i].rewardExpectText = computedRewardItems.value[0].rewardExpectText;
        }
      }
    };

    return {component, state, computedRewardItems, insert, pushRewardOptionItems, setRewardQtyFromItemsSum, remove, removeRewardOptionItem, onRewardQtyLimitChange, onRewardExpectTextChange, onApplyAllChange};
  }
});
</script>

<style lang="scss" scoped>
@import "../../styles/page.apply";

.step2 {
  position: relative;
  display: flex;
  flex-direction: column;
  gap: $px70;

  .form-group {
    display: flex;
    justify-content: space-between;
    gap: $px30;

    > .content {
      display: flex;
      flex-direction: column;
      gap: $px24;

      > .wrapper {
        .number {
          width: 30%;
        }
      }

      > .rewards {
        overflow: hidden;

        > li {
          position: relative;
          padding: 0 0 $px25 0;

          .actions {
            position: absolute;
            top: 0;
            right: 0;

            a {
              margin-left: $px15;
            }
          }

          > .wrapper {
            padding: $px20;
            border-radius: $px4;
            border: $px1 dashed #ddd;

            .info {
              color: $colorSecondary;
              margin-bottom: $px18;
            }

            .options {
              .reward-option-items {
                margin-bottom: $px15;
                position: relative;

                > .wrapper {
                  background: $colorBackground;
                  border-radius: $px4;
                  padding: $px20;

                  .row {
                    .remove {
                      padding: 0;

                      .btn {
                        display: block;
                        padding: 0;
                        height: $formHeight;
                      }
                    }

                    &:not(:last-child) {
                      margin-bottom: $px15;
                    }
                  }
                }
              }
            }

            .block {
              position: relative;

              .side {
                position: absolute;
                top: $px2;
                right: 0;
              }
            }

            .input {
              margin-bottom: $px18;
            }
          }
        }
      }
    }
  }

  @media(min-width: 992px) {
    > .rewards > li {
      > .wrapper .block.reward .reward-option-items .row .input {
        margin-bottom: 0;
      }
    }
  }

  @media(max-width: 991px) {
    gap: $px24;

    .form-group {
      flex-direction: column;
      gap: $px16;

      > .content {
        > .row {
          gap: $px16;
        }
      }
    }
  }
}
</style>